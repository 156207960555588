import { useState } from "react";
import {
  Box,
  Card,
  Stack,
  Divider,
  Typography,
  CardContent,
  IconButton,
  Button,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { useCart } from "../CartContext";
import { useSelector } from "react-redux";
import LoginModal from "src/sections/auth/modal/loginModal/LoginModal";
import { PaymentMethods } from "src/sections/payment";
import { LoadingButton } from "@mui/lab";
import { fNumber } from "src/utils/formatNumber";

const CartCheckoutTotalSummary = ({
  openCard,
  setOpenCard,
  method,
  setMethod,
  placeOrder,
  cashAvailable,
  taxAmount,
  serviceFee,
  error,
  setError,
  total,
  isLoading,
  disabled,
  setIsDisabled,
  setOpenCart,
  subTotal,
  discount,
  CancelPromotion,
  appliedPromotion,
  cardAvailableOnWeb,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  const { calculateSubTotal, selectedTip, isApplicable } = useCart();
  const { cardItems } = useSelector((state) => state.cardSlice);

  const { user, isLoggedIn } = useSelector((state) => state.login);
  let mode = localStorage.getItem("mode");
  const { data } = cardItems ?? {};
  const { venueDetail } = data ?? {}
  const { serviceFeesObject, configurations } = venueDetail ?? {}
  const { isServiceFeesOnWeb } = configurations ?? {}

  const renderServiceFee = () => {
    if (
      isServiceFeesOnWeb &&
      isApplicable(serviceFeesObject?.[mode]?.[method]?.applicable) && serviceFee > 0) {
      return (
        <Stack direction="row" justifyContent="space-between">
          <Typography
            sx={{ color: "text.secondary", fontWeight: "600" }}
          >
            Service Fee{" "}
            {`${serviceFeesObject?.[mode]?.[method]?.type === "Percentage"
              ? `(${serviceFeesObject?.[mode]?.[method]?.amount}%)`
              : ""
              }`}
          </Typography>
          <Typography variant="subtitle2">
            Rs. {fNumber(serviceFee)}
          </Typography>
        </Stack>
      );
    }
    return null;
  };

  return (
    <>
      <Box>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "700",
            marginLeft: "14px",
            fontSize: "25px",
            marginTop: isLoggedIn  ? "0px" : "80px",
            "@media (max-width: 600px)": {
              marginTop:"0px"
            },
            marginBottom: "10px",
          }}
        >
          Order Total
        </Typography>
      </Box>
      <Card
        sx={{
          border: "1px solid rgba(0, 0, 0, 0.10)",
          borderBottom: "none",
          borderRadius: "20px",
        }}
      >
        <CardContent>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ color: "text.secondary", fontWeight: "600" }}>
                Sub Total
              </Typography>
              {cardItems?.data?.items && cardItems?.data?.items?.length > 0 && (
                <Typography variant="subtitle2">
                  Rs. {fNumber(subTotal)}
                </Typography>
              )}
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ color: "text.secondary", fontWeight: "600" }}>
                Platform Fee
              </Typography>
              {cardItems?.data?.items && cardItems?.data?.items?.length > 0 && (
                <Typography variant="subtitle2">
                  Rs. 9.9
                </Typography>
              )}
            </Stack>
            {renderServiceFee()}
           {discount > 0 &&
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ color: "text.secondary", fontWeight: "600" }}>
                  Discount
                </Typography>

                <Typography variant="subtitle2">
                  Rs. {fNumber(discount)}
                </Typography>
              </Stack>
            }
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ color: "text.secondary", fontWeight: "600" }}>
                Tip
              </Typography>
              {cardItems?.data?.items && cardItems?.data?.items.length > 0 && (
                <Typography variant="subtitle2">
                  Rs. {selectedTip ? fNumber(selectedTip) : 0}
                </Typography>
              )}
            </Stack>

            {cardItems?.data?.venueDetail?.configurations?.isPayingTax && (
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ color: "text.secondary", fontWeight: "600" }}>
                  Tax
                </Typography>
                <Typography variant="subtitle2">
                  Rs. {taxAmount ? fNumber(taxAmount) : 0}
                </Typography>
              </Stack>
            )}

            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ color: "#FCA92E", fontWeight: "600" }}>
                Total Amount
              </Typography>
              {cardItems?.data?.items && cardItems?.data?.items.length > 0 && (
                <Typography variant="subtitle2" sx={{ color: "#FCA92E" }}>
                  Rs. {fNumber(total.toFixed(2))}
                </Typography>
              )}
            </Stack>
            <Typography>
              All transactions are secure and encrypted.
            </Typography>

            <Box
              sx={{
                borderRadius: "20px",
              }}
            >
              <PaymentMethods
                openCard={openCard}
                cardAvailableOnWeb={cardAvailableOnWeb}
                setOpenCard={setOpenCard}
                method={method}
                cashAvailable={cashAvailable}
                setMethod={setMethod}
                serviceFee={serviceFee}
                taxAmount={taxAmount}
                total={total}
                setError={setError}
                error={error}
                setIsDisabled={setIsDisabled}
                setOpenCart={setOpenCart}
                discount={discount}
                CancelPromotion={CancelPromotion}
                appliedPromotion={appliedPromotion}
              />
            </Box>
            {error && (
              <Typography
                variant="body2"
                style={{ color: "red", textAlign: "center" }}
              >
                {error}
              </Typography>
            )}

            {method === "cash" && cashAvailable && (
              <>
                <LoadingButton
                  fullWidth
                  color="inherit"
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  onClick={placeOrder}
                  disabled={(!user?.isPhoneVerified && isLoggedIn) || disabled}
                  sx={{
                    bgcolor: "#fda92d",
                    color: (theme) =>
                      theme.palette.mode === "black" ? "common." : "grey.800",
                    "&:hover": {
                      bgcolor: "#B66816",
                      color: (theme) =>
                        theme.palette.mode === "black"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                >
                  Place Order
                </LoadingButton>
              </>
            )}
          </Stack>
        </CardContent>
      </Card>

      {openModal && (
        <LoginModal
          width="sm"
          open={openModal}
          onClose={closeModal}
          setOpenModal={setOpenModal}
        />
      )}
    </>
  );
};

export default CartCheckoutTotalSummary;
