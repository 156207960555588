import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addTipToCart } from "src/redux/slices/cardSlice";
import api from "src/utils/axios";
import { fNumber } from "src/utils/formatNumber";
import { useCart } from "../CartContext";
import { UpsellingDialog } from "../Upselling/UpsellingDialog";

const CartModalAction = ({
  venueIdToBlock,
  onClose,
  setIsDisabled,
  isDisabled,
  serviceFee,
  setIsScanning,
  taxAmount,
  setOpenCarInformationModal,
  isUpsellingDialogOpen,
  setIsUpsellingDialogOpen
}) => {
  let orderType = localStorage.getItem("mode");
  const { tableData } = useSelector((state) => state.tableData);
  const { cardItems } = useSelector((state) => state?.cardSlice);
  const { isLoggedIn } = useSelector((state) => state.login);
  const { calculateFinalTotal, selectedTip } = useCart();
  const [upsellingItems, setUpsellingItems] = useState([]);
  const [fetchingItems, setFetchingItems] = useState(true);
  const { data } = cardItems ?? {};
  const { venueId, levelId } = data ?? {};

  const navigate = useNavigate();
  let dispatch = useDispatch();
  useEffect(() => {
    setIsDisabled(((orderType === "dineIn" || orderType === "QrDineIn") && tableData.length == 0) || (cardItems?.data.venueId == venueIdToBlock));
  }, [cardItems]);
  
  const handleUpsellingDialogClose = () => setIsUpsellingDialogOpen(false);

  const checkLogin = () => {
    if (isLoggedIn) {

      navigate(`/checkout`);
    }
    else {
      navigate(`/auth/login?type=${orderType}`)
    }
  }

  const handleCheckoutClick = () => {
    if (!upsellingItems?.length) {
      onClose();
      if (orderType == "pickUp") {
        checkLogin();
      } else if (orderType == "carHop") {
        setOpenCarInformationModal(true);
      } else {
        checkLogin();
      }
    } else {
      setIsUpsellingDialogOpen(true);
    }
    dispatch(addTipToCart(selectedTip));
  };


  let discount=0
  // Fetch upselling items from current venue
  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await api.get(
              `${process.env.REACT_APP_BACKEND_URL}/items/?levelId=${levelId}&venueId=${venueId}&isUpSellingItem=true&isAvailable=true`
            );
            setUpsellingItems(response?.data?.results || []);
            setFetchingItems(false);
        } catch (error) {
            console.log('Error while fetching items', error);
            setFetchingItems(false);
        }
    };

    fetchData();
}, [levelId, venueId]);


  return (
    <>
      <Box>
        {cardItems?.data?.items?.length > 0 && (
          <Box>
            {isDisabled && (orderType === "dineIn" || orderType === "QrDineIn") && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: 'center',
                    paddingBottom: "14px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "15px",
                      color:"red"
                    }}
                  >
                    Please scan the QR code on the table to proceed
                  </Typography>

                  <img
                    src="/assets/order/qrCode-1.svg"
                    alt="Processing icon"
                    style={{ height: "45px", width: "45px" }}
                    onClick={() => setIsScanning(true)}
                  />
                </Box>
              </>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Typography sx={{ fontWeight: "800", fontSize: "16px" }}>
                Total
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: "15px", }}
              >
                Rs.{" "}
                {fNumber((
                  Number(calculateFinalTotal(cardItems?.data?.items,selectedTip,discount)) +
                  Number(taxAmount) +
                  Number(serviceFee) +
                  9.9
                ).toFixed(2))}
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: "10px",
              }}
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleCheckoutClick}
                disabled={isDisabled || fetchingItems}
                sx={{
                  width: "100%",
                  height: "40px",
                  fontSize: "16px",
                }}
              >
                Checkout
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "16px",
                  }}
                ></Typography>
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      {isUpsellingDialogOpen &&
        <UpsellingDialog
          open={isUpsellingDialogOpen}
          onClose={handleUpsellingDialogClose}
          onCloseForCheckOutCart={onClose}
          items={upsellingItems}
          setOpenCarInformationModal={setOpenCarInformationModal}
        />}
    </>
  );
};

export default CartModalAction;
