import { useState, useEffect } from "react";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "src/redux/slices/loginSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { PATH_AUTH } from "../../routes/paths";
import Iconify from "../../components/iconify";
import FormProvider, { RHFTextField } from "../../components/hook-form";
import { useSnackbar } from "../../components/snackbar";
import GuestModalContent from "./modal/loginModal/GuestModalContent";
import { getVerifyCode } from "src/utils/Apis";
import { color } from "@mui/system";

export default function AuthLoginForm() {
  const [checkOrderType, setCheckType] = useState();
  const [toggle, setToggle] = useState();
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const type = searchParams.get("type");
    setCheckType(type);
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const apiData = {
    isEmail: "true",
  };
   const {  cardItems } = useSelector((state) => state.cardSlice);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/,
        "Please enter a valid email address"
      ),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await dispatch(loginUser(data));
      if (response.status === 200) {
        localStorage.setItem("access_token", response.token);
        if (response?.data?.isEmailVerified) {
          if (
            checkOrderType === "dineIn" ||
            checkOrderType === "pickUp" ||
            checkOrderType === "QrDineIn" ||
            checkOrderType === "QrPickUp" || 
            checkOrderType === "carHop"
          ) {
            navigate("/checkout");
          } else {
            navigate("/");
            // enqueueSnackbar("Login successfully");
          }
        } else {
          await getVerifyCode(apiData, response?.data?.id);
          let queryParams = new URLSearchParams();
          queryParams.append("verify", "Email");
          navigate(`/auth/verify?${queryParams.toString()}`, { state: { redirectedFromBack: true } } );
        }
      }
    } catch (error) {
      reset();
      setError("afterSubmit", {
        ...error,
        message: error?.response?.data?.message,
      });
    }
  };


  console.log("cardItems", cardItems.le)

  return (
    <>
      {!toggle ? (
        <>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack sx={{ mt: 10 }} spacing={2}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
         <h1 style={{textAlign:"center", color:"#f9931e",fontWeight:"bold",fontSize:"700",marginBottom:"19px"}}>Log in</h1>
         <RHFTextField name="email" label="Email address" />
              <RHFTextField
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <Stack alignItems="flex-end" sx={{ my: 2 }}>
              <Link
                to={PATH_AUTH.resetPassword}
                variant="subtitle2"
                style={{
                  color: "#FCA92E",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Forgot password
              </Link>
            </Stack>
            <Box sx={{ my: 2 }}>
              <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitSuccessful || isSubmitting}
                sx={{
                  bgcolor: "#FCA92E",
                  color: (theme) =>
                    theme.palette.mode === "light" ? "#000000" : "grey.800",
                  "&:hover": {
                    bgcolor: "#B66816",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                  },
                }}
              >
                Login
              </LoadingButton>
            </Box>
            <Divider
              sx={{
                my: 2.5,
                typography: "overline",
                color: "text.disabled",
              }}
            >
              or
            </Divider>

            {((checkOrderType === "dineIn" ||
              checkOrderType === "QrDineIn" ||
              checkOrderType === "QrPickUp" ||
              checkOrderType === "carHop" ||
              checkOrderType === "pickUp"  ) && (cardItems?.length > 0 || cardItems?.data?.items?.length)
              ) && (
              <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                onClick={() => setToggle(true)}
                // loading={isSubmitSuccessful || isSubmitting}
                sx={{
                  bgcolor: "#FCA92E",
                  color: (theme) =>
                    theme.palette.mode === "light" ? "#000000" : "grey.800",
                  "&:hover": {
                    bgcolor: "#B66816",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                  },
                }}
              >
                use as a guest
              </LoadingButton>
            )}
            <Stack
              direction="row"
              spacing={0.5}
              justifyContent="center"
              sx={{ mt: 3 }}
            >
              <Typography variant="body2">Don't have an account?</Typography>

              <Link
                to={PATH_AUTH.register}
                variant="subtitle2"
                style={{
                  color: "#FCA92E",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Create Account
              </Link>
            </Stack>
          </FormProvider>
        </>
      ) : (
        <GuestModalContent setToggle={setToggle} />
      )}
    </>
  );
}
