import { Box, Button, Divider, Grid, IconButton, Typography, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Iconify from "src/components/iconify/Iconify";
import VenueItmeModal from "../Venue Profiles/venueModal/VenueItmeModal";
import VenueProfileMenuCard from "../Venue Profiles/VenueProfileMenuCard";
import api from "src/utils/axios";
import { fetchImage } from "src/utils/fetchImage";
import { useNavigate } from "react-router";

export const UpsellingDialog = ({ open, onClose, items,onCloseForCheckOutCart, setOpenCarInformationModal }) => {
    const navigate = useNavigate();
    const [isVenueItemModalOpen, setIsVenueItemModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [venuesCoverDate, setVenuesCoverDate] = useState({});
    const { cardSlice, venueLocationModal, venues } = useSelector((state) => state) ?? {}
    const { venueIdToBlock } = venueLocationModal ?? {}
    const { cardItems } = cardSlice ?? {};
    const { data } = cardItems ?? {};
    const { venueId } = data ?? {};
    let orderType = localStorage.getItem("mode");
    const { isLoggedIn } = useSelector((state) => state.login);


    const closeVenueItemModal = () => {
        setIsVenueItemModalOpen(false)
    }

    const checkLogin = () => {
        if (isLoggedIn) {
            onClose()
            onCloseForCheckOutCart()
            navigate(`/checkout`);
        }
        else {
            navigate(`/auth/login?type=${orderType}`)
        }
    }

    const handleSkip = () => {
        if (orderType == "pickUp") {
            checkLogin();
        } else if (orderType == "carHop") {
            setOpenCarInformationModal(true);
        } else {
            checkLogin();
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const venue = venues?.venues?.find((venue) => venue.id === venueId);
                const venueResponse = await api.get(
                    `${process.env.REACT_APP_BACKEND_URL}/venues/b2c/${venueId}`
                );
                const res = await fetchImage(venueResponse?.data?.photoURL);

                const response = {
                    detail: venueResponse?.data,
                    image: res,
                    distance: venue?.distance,
                }
                setVenuesCoverDate(response);

            } catch (error) {
                console.log('error while fetching venues', error?.message)
            }
        }
        )()
    }, [venueId])

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'md'}
                open={open}
                onClose={onClose}
                sx={{
                    '& .MuiDialog-paper': {
                       minHeight: 'calc(100% - 64px)',
                    },
                }} >
                {/* Dialog Header */}
                <DialogTitle>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "row",
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: "700",
                                fontSize: "25px",
                                m: 0,
                                p: 0,
                            }}
                        >
                            You may also like this

                        </Typography>

                        <Box sx={{ m: 0, p: 0 }}>
                            <IconButton
                                aria-label="close"
                                onClick={() => onClose(!open)}
                                sx={{
                                    position: {
                                        xs: "static",
                                    },
                                    top: 20,
                                    right: 8,
                                }}
                            >
                                <Iconify
                                    sx={{
                                        height: 25,
                                        width: 25,
                                    }}
                                    icon="mdi:close"
                                    color="#fca92e"
                                />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <Box>
                    <Divider />
                </Box>
                {/* Content */}

                <DialogContent
                    sx={{
                        padding: "24px",
                    }}
                >
                    <Box>
                        <Grid container spacing={1}>
                            {items?.map((item) => (
                                <>
                                    <Grid item xs={12} md={6}>
                                        <VenueProfileMenuCard
                                            item={item}
                                            width={'100%'}
                                            setOrderItem={() => {
                                                setIsVenueItemModalOpen(true)
                                                setSelectedItem(item)
                                            }}
                                        />
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </Box>
                </DialogContent>
                <Box
                    sx={{
                        marginTop: "10px",
                        padding: "24px",
                    }}
                >
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={handleSkip}
                        sx={{
                            width: "100%",
                            height: "40px",
                            fontSize: "16px",
                        }}
                    >
                        Skip, continue to checkout
                        <Typography
                            sx={{
                                fontWeight: "700",
                                fontSize: "16px",
                            }}
                        ></Typography>
                    </Button>
                </Box>
            </Dialog>

            <VenueItmeModal
                venueIdToBlock={venueIdToBlock}
                width="md"
                open={isVenueItemModalOpen}
                item={selectedItem}
                venuesCoverData={venuesCoverDate}
                modeOfVenue={{ isPickUp: cardSlice?.isPickUp, isDineIn: cardSlice?.isDineIn }}
                venueId={venueId}
                onClose={closeVenueItemModal}
                setOpenConfirm={setIsVenueItemModalOpen}
            />
        </>
    )
}
