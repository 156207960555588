import { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useLocation } from "react-router";
import LocationModal from "../locationSearchModal/LocationModal";
import useGeolocation from "src/utils/geolocation";

const HeaderSearchBar = () => {
  const location = useLocation();

  const [openCart, setOpenCart] = useState(false);
  const [address, setAdress] = useState(localStorage.getItem("address"));
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleClose = () => {
    setOpenCart(false);
  };

  useEffect(() => {
    const tempAddress = localStorage.getItem("tempAddress");
    const address = localStorage.getItem("address");
    if (address != tempAddress && tempAddress) {
      localStorage.setItem("address", tempAddress);
      setAdress(tempAddress);    }
  }, []);

  useEffect(() => {
    const handleAddressUpdate = () => {
      setAdress(localStorage.getItem("address"));
    };
    window.addEventListener("addressUpdated", handleAddressUpdate);
    return () => {
      window.removeEventListener("addressUpdated", handleAddressUpdate);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const truncateAddress = (address) => {
    if (address) {
      const maxLength = windowWidth <= 350 ? 20 : 34;
      const truncated = address?.substr(0, maxLength);
      const lastSpaceIndex = truncated?.lastIndexOf(" ");

      if (lastSpaceIndex !== -1 && lastSpaceIndex < maxLength) {
        return truncated + "...";
      } else {
        return truncated + "...";
      }
    } else {
      return "Enter your address";
    }
  };
  return (
    <>
      {location.pathname !== "/" &&
        location?.pathname !== "/auth/login" &&
        location?.pathname !== "/auth/register" &&
        location?.pathname !== "/auth/reset-password" &&
        location?.pathname !== "/auth/AuthVerifyCodeForm" &&
        location?.pathname !== "/auth/verify" &&
        location?.pathname !== "/auth/verifysuccess" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {address && (
              <Typography
                sx={{
                  fontSize: "12px",

                  "@media (max-width: 330px)": {
                    fontSize: "10px",
                  },

                  display: { xs: "block", sm: "none" },
                }}
              >
                Your location
              </Typography>
            )}

            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "600",
                  color: "#FCA92E",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => setOpenCart(true)}
              >
                {truncateAddress(address)}

                <img
                  src="/assets/header-tabs/dropDown.svg"
                  alt="Cart icon"
                  style={{
                    height: "8px",
                    width: "8px",
                    marginTop: "2px",
                    marginLeft: "2px",
                  }}
                />
              </Typography>
            </Box>

            <Button
              type="submit"
              variant="outlined"
              sx={{
                height: "45px",
                display: "flex",
                alignItems: "center",
                marginLeft: "40px",
                gap: "10px",
                paddingLeft: "20px",
                paddingRight: "80px",
                "@media (max-width: 600px)": {
                  width: "100%",
                  height: "40px",
                  display: "none",
                },
              }}
              onClick={() => setOpenCart(true)}
            >
              <Iconify
                sx={{
                  color: "#black",
                  height: 30,
                  width: 30,
                  display: { xs: "none", sm: "block" },
                  "@media (max-width: 1200px)": {
                    height: 20,
                    width: 20,
                  },
                  "@media (max-width: 1000px)": {
                    width: 20,
                    height: 20,
                  },
                }}
                icon={"mdi:location"}
              />
              <Typography
                sx={{
                  maxWidth: "200px",
                  // overflow: "hidden",
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  "@media (max-width: 600px)": {
                    fontSize: "14px",
                  },
                }}
              >
                {truncateAddress(address)}
              </Typography>
            </Button>
          </Box>
        )}
      <LocationModal width="md" open={openCart} onClose={handleClose} />
    </>
  );
};

export default HeaderSearchBar;
