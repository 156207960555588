import { Box, Typography, Button } from "@mui/material";
import Image from "src/components/image/Image";

const Ready = ({ detail, toggleContent, setToggleContent }) => {
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "25px",
          fontWeight: "bold",
        }}
      >
        Your order is ready <br />
        <Box mt={2} mb={1}>
          <Image
            src="/assets/order/completedModal.svg"
            alt="image not exis"
            width="100%"
          />
        </Box>
        <Typography sx={{ margin: "0px" }}>
          {detail?.state === "finished"
            ? detail?.orderType === "pickUp" || detail?.orderType === "QrPickUp" 
              ? "Your order is ready for pickup."
              : detail.orderType === 'carHop'?
                "Your order is ready to be deliver."
              : detail?.orderType === "dineIn" ||
                detail?.orderType === "QrDineIn"
              ? "Your order is ready for serving."
              : "The restaurant has prepared your order."
            : ""}
        </Typography>
        <span style={{ color: "#fda92d", fontSize: "14px" }}>
          Order # {detail?.billNumber}
        </span>
      </Typography>

      <Box
        mt={3}
        mb={2}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Image
          src="/assets/order/ready.gif"
          sx={{ width: "50%" }}
          alt="image not exis"
        />
      </Box>

      <Box mt={2} mb={4}>
        <Button
          onClick={() => setToggleContent(!toggleContent)}
          type="button"
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            height: "40px",
            fontSize: "16px",
          }}
        >
          {toggleContent ? "Hide order details" : "show order details"}
        </Button>
      </Box>
    </Box>
  );
};
export default Ready;
