import { Box, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import Iconify from "src/components/iconify/Iconify";
import { calculateAndRoundTax } from "src/utils/tax";
import { useCart } from "../CartContext";
import PromotionModalContent from "./PromotionModalContent";

const PromotionModal = ({
    width,
    open,
    onClose,
    promotion
}) => {

    return (
        <>
            <ConfirmDialog width={width} open={open} onClose={onClose}>
                <>
                    <DialogTitle
                        sx={{
                            "@media (max-width: 600px)": {
                                padding: "12px",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                flexDirection: "row",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "700",
                                    fontSize: "25px",
                                    m: 0,
                                    p: 0,
                                }}
                            >
                                Promotions
                            </Typography>
                            <Box sx={{ m: 0, p: 0 }}>
                                <IconButton
                                    aria-label="close"
                                    onClick={() => onClose(!open)}
                                    sx={{
                                        position: {
                                            xs: "static",
                                        },
                                        top: 20,
                                        right: 8,
                                    }}
                                >
                                    <Iconify
                                        sx={{
                                            height: 25,
                                            width: 25,
                                        }}
                                        icon="mdi:close"
                                        color="#fca92e"
                                    />
                                </IconButton>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <Box>
                        <Divider />
                    </Box>
                    <DialogContent
                        sx={{
                            typography: "body2",
                            "@media (max-width: 600px)": {
                                padding: "12px",
                            },
                        }}
                    >
                        <PromotionModalContent promotion={promotion}/>
                    </DialogContent>
                    <Box>
                        <Divider />
                    </Box>

                    {/* <DialogActions
              sx={{
                display: "block",
                "@media (max-width: 600px)": {
                  padding: "12px !important",
                },
              }}
            >
              <CartModalAction
                venueIdToBlock={venueIdToBlock}
                open={open}
                onClose={onClose}
                taxAmount={taxAmount}
                serviceFee={serviceFee}
                setIsDisabled={setIsDisabled}
                isDisabled={isDisabled}
                setIsScanning={setIsScanning}
                setOpenCarInformationModal={setOpenCarInformationModal}
                isUpsellingDialogOpen={isUpsellingDialogOpen}
                setIsUpsellingDialogOpen={setIsUpsellingDialogOpen}
              />
            </DialogActions> */}
                </>

            </ConfirmDialog>
        </>
    );
};
export default PromotionModal;
