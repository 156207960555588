import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
const UserLocationSettingsContext = createContext();

export const UserLocationEnabledProvider = ({ children }) => {
  const [getUserCurrentLocation, setGetUserCurrentLocation] = useState(null);
  const [cardAvailableOnWeb, setCardAvailableOnWeb] = useState(null);


  useEffect(() => {
    const fetchCardSettings = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/generalConfiguration/getOneGeneralConfiguration`
        );
        if (res.data) {
          setGetUserCurrentLocation(res?.data?.getUserCurrentLocation);
          setCardAvailableOnWeb(res?.data?.cardAvailableOnWeb)
        }
      } catch (err) {
        console.error("Error fetching card settings:", err);
      }
    };

    fetchCardSettings();
  }, []);

  return (
    <UserLocationSettingsContext.Provider value={{ getUserCurrentLocation, cardAvailableOnWeb}}>
      {children}
    </UserLocationSettingsContext.Provider>
  );
};

export const useUserLocationEnabled = () => {
  return useContext(UserLocationSettingsContext);
};
