import {
  Box,
  Typography,
  Divider,
  IconButton,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import Image from "src/components/image/Image";
import { DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useState, useEffect } from "react";
import { fetchImage } from "src/utils/fetchImage";
import { useCart } from "../CartContext";
import { fNumber } from "src/utils/formatNumber";
import capitalizeWords from "src/utils/capitalizeWords";

import { StarIcon } from "src/theme/overrides/CustomIcons";

const Ordermodal = ({ detail, width, open, onClose }) => {
  const [imageURLs, setImageURLs] = useState([]);
  const { calculateMenuItemTotal } = useCart();
  const [venueImageURL, setVenueImageURL] = useState("");
  const [itemWiseReview, setItemWiseReview] = useState(false);

  useEffect(() => {
    const fetchVenueImage = async () => {
      if (detail?.venueId?.photoURL) {
        try {
          const res = await fetchImage(detail.venueId.photoURL);
          setVenueImageURL(res);
        } catch (error) {
          console.error("Error fetching venue image:", error);
        }
      }
    };

    fetchVenueImage();
  }, [detail?.venueId?.photoURL]);

  useEffect(() => {
    const fetchItemImages = async () => {
      if (detail?.items) {
        try {
          const imagePromises = detail.items.map((item) =>
            fetchImage(item?.photoURL)
          );
          const fetchedImages = await Promise.all(imagePromises);
          setImageURLs(fetchedImages);
        } catch (error) {
          console.error("Error fetching item images:", error);
        }
      }
    };

    if (open) {
      fetchItemImages();
    }
  }, [detail?.items, open]);

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <StarIcon
        key={index}
        style={{
          color: rating > index ? "#fca92e" : "#ccc",
          fontSize: "20px",
        }}
      />
    ));
  };

  const questions = [
    {
      key:
        detail?.ordertype === "QrDineIn" || detail?.ordertype === "dineIn"
          ? "ambiance"
          : "waitTime",
      label:
        detail?.ordertype === "QrDineIn" || detail?.ordertype === "dineIn"
          ? "Ambiance"
          : "Wait Time",
    },
    { key: "foodQuality", label: "Food Quality" },
    { key: "service", label: "Service" },
    { key: "overAllExperience", label: "Overall Experience" },
    { key: "valueForMoney", label: "Value for Money" },
  ];

  return (
    <>
      <ConfirmDialog width={width} open={open} onClose={onClose}>
        <DialogTitle sx={{ paddingBottom: "0" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Image
                src={venueImageURL ? venueImageURL : "/assets/placeholder.png"}
                alt={"image"}
                style={{
                  height: "100px",
                  width: "140px",
                  borderRadius: "5px",
                }}
              />
              <Box variant="h2">
                {detail?.venueId?.name}
                <Typography sx={{ fontWeight: 600 }}>
                  Order # {detail?.billNumber}
                </Typography>
              </Box>
            </Box>
            <IconButton aria-label="close" onClick={onClose}>
              <Iconify
                sx={{
                  height: 25,
                  width: 25,
                }}
                icon="mdi:close"
                color="#fca92e"
              />
            </IconButton>
          </Box>
        </DialogTitle>
        <Box mt={1}>
          <Divider />
        </Box>
        <DialogContent sx={{ typography: "body2" }}>
          <Box>
            {detail?.items?.map((item, index) => {
              const isLastItem = index === detail.items.length - 1;

              return (
                <>
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                    mb={1}
                    mt={1}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Image
                        src={
                          imageURLs[index]
                            ? imageURLs[index]
                            : "/assets/placeholder.png"
                        }
                        alt={"image"}
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "5px",
                        }}
                      />
                      <Box>
                        <Box sx={{ fontSize: "14px" }}>
                          {" "}
                          {item?.qty} X {capitalizeWords(item?.name)}{" "}
                        </Box>
                        {item?.groups && item?.groups?.length > 0 && (
                          <>
                            {item?.selectedVariant && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    color: "#666",
                                  }}
                                >
                                  Variant:
                                </Typography>
                                <Typography sx={{ ml: 1, fontSize: 12 }}>
                                  {item?.selectedVariant?.name}
                                </Typography>
                              </Box>
                            )}
                            <>
                              {item?.groups?.map((sauce) => (
                                <Typography
                                  key={index}
                                  variant="caption"
                                  sx={{
                                    color: "#666",
                                    display: "flex",
                                    gap: "2px",
                                    fontWeight: "bold",
                                    "@media (max-width: 600px)": {
                                      fontSize: "10px",
                                    },
                                  }}
                                >
                                  {sauce?.name} :
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "#666",
                                      display: "flex",
                                      marginLeft: "5px",
                                      gap: "2px",
                                      "@media (max-width: 600px)": {
                                        fontSize: "10px",
                                      },
                                    }}
                                  >
                                    {sauce?.items?.map((item, index) => (
                                      <span key={index}>
                                        {item?.item}
                                        {index !== sauce.items.length - 1 &&
                                          ", "}
                                      </span>
                                    ))}
                                  </Typography>
                                </Typography>
                              ))}
                            </>
                          </>
                        )}

                        <Box sx={{ fontSize: "14px", color: "red" }}>
                          {item?.notes}{" "}
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ color: "black" }}>
                      {" "}
                      {calculateMenuItemTotal(item)}
                    </Box>
                  </Box>
                  {!isLastItem && <Divider />}
                </>
              );
            })}
          </Box>
        </DialogContent>
        <Divider />

        <DialogActions sx={{ paddingTop: "8px !important" }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                Sub Total
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  mt: 0,
                  color: "black",
                }}
                align="right"
              >
                Rs: {fNumber(detail?.subTotal)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                Platform Fee
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                sx={{ fontWeight: "500", fontSize: "14px", color: "black" }}
                align="right"
              >
                Rs: 9.9
              </Typography>
            </Grid>

            {detail?.serviceFees > 0 && (
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                  Service Fee
                </Typography>
              </Grid>
            )}

            {detail?.serviceFees > 0 && (
              <Grid item xs={6}>
                <Typography
                  sx={{ fontWeight: "500", fontSize: "14px", color: "black" }}
                  align="right"
                >
                  Rs: {fNumber(detail?.serviceFees)}
                </Typography>
              </Grid>
            )}

            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                Tip
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "black",
                }}
                align="right"
              >
                Rs. {fNumber(detail?.tip)}
              </Typography>
            </Grid>

            {detail?.tax > 0 && (
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                  Tax
                </Typography>
              </Grid>
            )}
            {detail?.tax > 0 && (
              <Grid item xs={6}>
                <Typography
                  sx={{ fontWeight: "500", fontSize: "14px", color: "black" }}
                  align="right"
                >
                  Rs: {fNumber(detail?.tax)}
                </Typography>
              </Grid>
            )}

            <Grid item xs={6}>
              <Typography
                sx={{ fontWeight: "500", fontSize: "15px", fontWeight: "bold" }}
              >
                Total
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "black",
                }}
                align="right"
              >
                Rs: {fNumber(detail?.total)}
              </Typography>
            </Grid>

            {detail?.orderReviewed == true && (
              <>
                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography variant="subtitle1">Review</Typography>

                  <IconButton
                    onClick={() => setItemWiseReview(!itemWiseReview)}
                  >
                    <Iconify
                      icon={
                        itemWiseReview ? "mdi:chevron-up" : "mdi:chevron-down"
                      }
                    />
                  </IconButton>
                </Box>

                {itemWiseReview && (
                  <>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      {questions.map((question) => (
                        <Box
                          key={question.key}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 1,
                            justifyContent: "space-between",
                            gap: "10px",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                flexGrow: 1,
                                fontSize: {
                                  xs: "13px",
                                  sm: "14px",
                                  md: "14px",
                                },
                              }}
                            >
                              {question.label}
                            </Typography>
                          </Box>
                          <Box>
                            {renderStars(
                              detail?.reviewId?.overallOrderRating[question.key]
                            )}
                          </Box>
                        </Box>
                      ))}
                    </Grid>

                    {detail?.reviewId?.itemsRating?.length > 0 && (
                      <Grid xs={12} sx={{ mb: 2 }}>
                        <Divider />
                        <Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
                          Items
                        </Typography>

                        <Box>
                          {detail?.items?.map((item, index) => {
                            const itemRating =
                              detail?.reviewId?.itemsRating?.find(
                                (rating) => rating.id === item.id
                              )?.itemRating || 0;
                            return (
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                                mb={1}
                                mt={1}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <Image
                                    src={
                                      imageURLs[index]
                                        ? imageURLs[index]
                                        : "/assets/placeholder.png"
                                    }
                                    alt={"image"}
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      borderRadius: "5px",
                                    }}
                                  />
                                  <Box>
                                    <Box
                                      sx={{
                                        fontSize: {
                                          xs: "13px",
                                          sm: "14px",
                                          md: "14px",
                                        },
                                      }}
                                    >
                                      {item?.qty} X {capitalizeWords(item?.name)}
                                    </Box>

                                    {item?.groups &&
                                      item?.groups?.length > 0 && (
                                        <>
                                          {item?.selectedVariant && (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: 12,
                                                  fontWeight: "bold",
                                                  color: "#666",
                                                }}
                                              >
                                                Variant:
                                              </Typography>
                                              <Typography
                                                sx={{ ml: 1, fontSize: 12 }}
                                              >
                                                {item?.selectedVariant?.name}
                                              </Typography>
                                            </Box>
                                          )}
                                          <>
                                            {item?.groups?.map((sauce) => (
                                              <Typography
                                                key={index}
                                                variant="caption"
                                                sx={{
                                                  color: "#666",
                                                  display: "flex",
                                                  gap: "2px",
                                                  fontWeight: "bold",
                                                  "@media (max-width: 600px)": {
                                                    fontSize: "10px",
                                                  },
                                                }}
                                              >
                                                {sauce?.name} :
                                                <Typography
                                                  variant="caption"
                                                  sx={{
                                                    color: "#666",
                                                    display: "flex",
                                                    marginLeft: "5px",
                                                    gap: "2px",
                                                    "@media (max-width: 600px)":
                                                      {
                                                        fontSize: "10px",
                                                      },
                                                  }}
                                                >
                                                  {sauce?.items?.map(
                                                    (item, index) => (
                                                      <span key={index}>
                                                        {item?.item}
                                                        {index !==
                                                          sauce.items.length -
                                                            1 && ", "}
                                                      </span>
                                                    )
                                                  )}
                                                </Typography>
                                              </Typography>
                                            ))}
                                          </>
                                        </>
                                      )}

                                    <Box
                                      sx={{ fontSize: "14px", color: "red" }}
                                    >
                                      {item?.notes}
                                    </Box>

                                    <Box
                                      sx={{
                                        display: { xs: "block", sm: "none" },
                                        mt: 1,
                                        ml: -0.5,
                                      }}
                                    >
                                      <Box>{renderStars(itemRating)}</Box>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box
                                  sx={{ display: { xs: "none", sm: "block" } }}
                                >
                                  <Box>{renderStars(itemRating)}</Box>
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      </Grid>
                    )}

                    {detail?.reviewId?.comments && (
                      <TextField
                        label="Comments"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={2}
                        value={detail?.reviewId?.comments}
                        sx={{ mb: 2, mt: 4 }}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </DialogActions>
      </ConfirmDialog>
    </>
  );
};

export default Ordermodal;
