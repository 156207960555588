import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
export default function useHandleDirectRoute(checkRoute) {
    const navigate = useNavigate();
       useEffect(() => {
        if(checkRoute == undefined){
            navigate("/")
           }
    }, []);
     
}
