import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import VenueHeader from "./VenueHeader";
import VenueCuisines from "./VenueCuisines";
import FooterLogin from "src/layouts/compact/FooterLogin";
import { useSelector } from "react-redux";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import VenueNotAvailableModal from "./VenueNotAvailable/VenueNotAvailableModal";
import { useLocation } from "react-router-dom";
import { selectedVenue } from "src/utils/venueSelectionUtils";
import { useDispatch } from "react-redux";
import DemoCarouselsPage from "../components/extra/DemoCarouselsPage";

const VenueMainPage = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.venues);
  const { state } = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(state?.errorMessage || null);
  const handleClose = () => {
    setOpenModal(false);
    setErrorMessage(null);
  };

  const fetchLocation = async (type) => {
    try {
      await selectedVenue(dispatch, type, true);
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  useEffect(() => {
    const message = localStorage.getItem("VenueNotAvailableMessageSeen");
    if (
      errorMessage &&
      errorMessage == "Venue Not Available" &&
      message == "false"
    ) {
      localStorage.setItem("VenueNotAvailableMessageSeen", true);
      setOpenModal(true);
    }
    const modeType= localStorage.getItem("mode");
    fetchLocation(modeType);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Egora-Venues</title>
      </Helmet>
      <VenueHeader />
      {/* <DemoCarouselsPage/> */}
      {errorMessage && (
        <VenueNotAvailableModal
          width="md"
          open={openModal}
          onClose={handleClose}
        />
      )}

      {isLoading ? (
        <LoadingScreen width="100px" height="100px" marginT="0" h="100vh" />
      ) : (
        <VenueCuisines />
      )}
      <FooterLogin />
    </div>
  );
};

export default VenueMainPage;
