import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { fetchImage } from "src/utils/fetchImage";
import Image from "src/components/image/Image";
import { splitText } from "src/utils/splitText";
import capitalizeWords from "src/utils/capitalizeWords";

const VenueMenuItemModal = ({ item,selectedVariant }) => {
  const [imageURL, setImageURL] = useState("");
  const getImage = useCallback(async () => {
    try {
      const res = await fetchImage(item?.photoURL);
      setImageURL(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [item]);

  useEffect(() => {
    getImage();
  }, [getImage]);
  
  const formatPrice = (value) => {
    return Math.round(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

 
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          "@media (max-width: 600px)": {
            display: "block",
          },
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            objectFit: "cover",
            justifyContent: "center",
           
            
          }}
        >
          <Image
            src={imageURL?imageURL:'/assets/placeholder.png'}
            alt={item?.name}
            sx={{
              height: "100px",
              width: "120px",
              borderRadius: "5px",
              marginBottom: "10px",
              marginRight:"10px"
                           
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="subtitle1">

            {capitalizeWords(item?.name)}
          </Typography>
          <Typography
            sx={{
              color: "#F08203",
              marginTop: "4px",
              fontSize: "16px",
              "@media (max-width: 1440px)": {
                fontSize: "15px",
              },
            }}
          >
            Rs.{" "}
            {selectedVariant
                    ? (formatPrice(parseFloat(selectedVariant.price)))
                    : (formatPrice(parseFloat(item.price)))}
          </Typography>
          <Typography
            sx={{
              textAlign: "justify",
              // fontSize: "16px",
              display: "flex",
              flexWrap: "wrap",
              "@media (max-width: 1440px)": {
                fontSize: "15px",
              },
              "@media (max-width: 600px)": {
                fontSize: "14px",
              },
            }}
          >
            {splitText(item?.description)}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default VenueMenuItemModal;
