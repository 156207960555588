// import PropTypes from "prop-types";
// import { useState } from "react";
// // @mui
// import { Dialog, DialogContent } from "@mui/material";
// // components
// import Iconify from "../../components/iconify";
// import MenuPopover from "../../components/menu-popover";
// import XPayPayment from "src/pages/PaymentCustomPage";

// // ----------------------------------------------------------------------

// PaymentNewCardDialog.propTypes = {
//   onClose: PropTypes.func,
// };

// export default function PaymentNewCardDialog({
//   data,
//   onClose,
//   total,
//   method,
//   ...other
// }) {
//   const [openPopover, setOpenPopover] = useState(null);

//   const handleOpenPopover = (event) => {
//     setOpenPopover(event.currentTarget);
//   };

//   const handleClosePopover = () => {
//     setOpenPopover(null);
//   };

//   return (
//     <>
//       <Dialog maxWidth="xs" onClose={onClose} {...other}>
//         <DialogContent sx={{ overflow: "unset" }}>
//           <XPayPayment method={method} onClose={onClose} />
//         </DialogContent>
//       </Dialog>
//     </>
//   );
// }




import PropTypes from "prop-types";
import { useState } from "react";
// components
import Iconify from "../../components/iconify";
import MenuPopover from "../../components/menu-popover";
import XPayPayment from "src/pages/PaymentCustomPage";

// ----------------------------------------------------------------------

PaymentNewCardComponent.propTypes = {
  onClose: PropTypes.func,
};

export default function PaymentNewCardComponent({ data, onClose, method,total, serviceFee,
  taxAmount, error, setError,setOpenCart, setIsDisabled,
  discount,appliedPromotion,...other }) {
  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <div style={{ padding: "16px", border: "1px solid #ccc", borderRadius: "8px", marginTop: "8px" }}>
      {/* Payment form or content that was originally in the dialog */}
      <XPayPayment method={method} onClose={onClose} total={total} serviceFee={serviceFee}
      taxAmount={taxAmount} error={error} setError={setError} setIsDisabled={setIsDisabled} setOpenCart={setOpenCart}
      discount={discount} appliedPromotion={appliedPromotion} />
    </div>
  );
}
