import {
  Box,
  Divider,
  IconButton,
  Button,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { DialogTitle, DialogContent } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReviewModal from "../review/ReviewModal";

const OrderStatusModal = ({ detail, width, orderStatusModal, onClose }) => {
  const { isLoggedIn } = useSelector((state) => state.login);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleOpenReviewModal = () => {
    setReviewModalOpen(true);
  };

  const goToOrder = () => {
    const orderId = orderStatusModal?.detail?.id;

    if (isLoggedIn) {
      navigate(`/order`, { state: { orderId } });
    } else {
      navigate(`/review?orderId=${orderId}`);
    }
  };

  return (
    <>
      <ConfirmDialog
        width={width}
        open={orderStatusModal?.openModal}
        onClose={onClose}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box variant="h2">
                {orderStatusModal?.detail?.state === "abort" && (
                  <Typography variant="h6" component="h2">
                    Your order has been declined from{" "}
                    {orderStatusModal?.detail?.venueId?.name}.
                  </Typography>
                )}
                {orderStatusModal?.detail?.state === "completed" && (
                  <Typography variant="h6" component="h2">
                    Your order has been completed.
                  </Typography>
                )}
              </Box>
            </Box>
            <IconButton aria-label="close" onClick={onClose}>
              <Iconify
                sx={{
                  height: 25,
                  width: 25,
                }}
                icon="mdi:close"
                color="#fca92e"
              />
            </IconButton>
          </Box>
          {(isLoggedIn && orderStatusModal?.detail?.state === "completed" ) && (
            <Box mt={3}>
              <Divider />
            </Box>
          )}
        </DialogTitle>
        {orderStatusModal?.detail?.state === "completed" && (
         <DialogContent sx={{ typography: "body2" }}>
          <MuiLink
            sx={{
              fontWeight: "bold",
              display: "block",
              mb: 3,
              cursor: "pointer",
            }}
            color="primary"
            onClick={() => {
              goToOrder();
              handleOpenReviewModal();
            }}
          >
            Want to Add Review
          </MuiLink>
        </DialogContent> 
        )}
      </ConfirmDialog>

      <ReviewModal
        open={reviewModalOpen}
        onClose={onClose}
        detail={orderStatusModal?.detail}
        width="sm"
      />
    </>
  );
};

OrderStatusModal.propTypes = {
  goToOrder: PropTypes.func,
};

export default OrderStatusModal;
