import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Divider,
  Switch,
  TextField,
  Link as MuiLink,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import Image from "src/components/image";
import { StarIcon } from "src/theme/overrides/CustomIcons";
import { fetchImage } from "src/utils/fetchImage";
import { fNumber } from "src/utils/formatNumber";
import { Container } from "@mui/system";
import { useSnackbar } from "src/components/snackbar";
import { useNavigate } from "react-router";
import LoadingScreen from "src/components/loading-screen";
import capitalizeWords from "src/utils/capitalizeWords";

const ReviewPage = () => {
  const [ratings, setRatings] = useState({
    ambiance: 0,
    waitTime: 0,
    foodQuality: 0,
    service: 0,
    overAllExperience: 0,
    valueForMoney: 0,
  });

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [imageURLs, setImageURLs] = useState([]);
  const [venueImageURL, setVenueImageURL] = useState("");
  const [itemWiseReview, setItemWiseReview] = useState(false);
  const [itemRatings, setItemRatings] = useState({});
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [reviewExists, setReviewExists] = useState(null);
  const [checkAlreadyReviewExists, setCheckAlreadyReviewExists] =
    useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("orderId");


  useEffect(() => {
    if (!id) {
      navigate('/'); 
    }
  }, [id]);

  const checkReviewExists = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/review/checkExists?orderId=${id}`
      );
      if (response.status === 200) {
        setIsLoading(false);
        const res = await fetchImage(response?.data?.venueId?.photoURL);
        setVenueImageURL(res);
        setReviewExists(response.data);

        const imagePromises = response?.data?.items?.map((item) =>
          fetchImage(item?.photoURL)
        );
        const fetchedImages = await Promise.all(imagePromises);
        setImageURLs(fetchedImages);
      }
      if (response.status === 204) {
        setCheckAlreadyReviewExists(true);
      }
      console.log("response.data.exists", response.data);
    } catch (error) {
      console.error("Error checking review existence:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      checkReviewExists();
    }
  }, [id]);

  const handleRatingChange = (question, rating) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [question]: prevRatings[question] === rating ? 0 : rating,
    }));
  };

  const handleItemRatingChange = (itemId, rating) => {
    setItemRatings((prevItemRatings) => ({
      ...prevItemRatings,
      [itemId]: prevItemRatings[itemId] === rating ? 0 : rating,
    }));
  };

  const ratingMessages = {
    ambiance: " Ambiance",
    waitTime: "Wait time",
    foodQuality: "Food quality",
    service: "Service",
    overAllExperience: "Overall experience",
    valueForMoney: "Value for money",
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const orderId = reviewExists?.id;
    const venueId = reviewExists?.venueId?.id;
    const levelId = reviewExists?.levelId;

    const requestBody = {
      overallOrderRating: ratings,
      orderId,
      venueId,
      levelId,
    };

    if (itemWiseReview) {
      const itemsRating = Object.keys(itemRatings).map((itemId) => ({
        id: itemId,
        itemRating: itemRatings[itemId],
      }));
      requestBody.itemsRating = itemsRating;
    }

    if (comment.trim() !== "") {
      requestBody.comments = comment;
    }

    if (itemWiseReview) {
      const allItemsRated = reviewExists.items.every(
        (item) => itemRatings[item.id] > 0
      );
      if (!allItemsRated) {
        enqueueSnackbar("Please rate all items before submitting.", {
          variant: "warning",
        });
        setIsLoading(false);
        return;
      }
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/review`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        console.log("Review submitted successfully:", response.data);
        enqueueSnackbar("Review Submitted successfully!");
        navigate("/reviewSuccess");
      } else {
        console.error("Failed to submit review:", response.data);
        enqueueSnackbar("Failed to submit review. Please try again later.");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        enqueueSnackbar("Review for this order already submitted.");
      } else {
        console.error("Error submitting review:", error);
        enqueueSnackbar("Failed to submit review. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderStars = (question, isItem = false, itemId = null) => {
    return [...Array(5)].map((_, index) => {
      const isSelected = isItem
        ? itemRatings[itemId] > index
        : ratings[question] > index;

      return (
        <StarIcon
          key={index}
          onClick={() => {
            if (isItem) {
              handleItemRatingChange(itemId, index + 1);
            } else {
              handleRatingChange(question, index + 1);
            }
          }}
          sx={{
            cursor: "pointer",
            color: isSelected ? "#fca92e" : "#ccc",
            fontSize: {
              xs: "20px",
              sm: "30px",
              md: "30px",
            },
            margin: "0 2px",
          }}
        />
      );
    });
  };

  const questionsToRate = [
    reviewExists?.ordertype === "QrDineIn" ||
    reviewExists?.ordertype === "dineIn"
      ? "ambiance"
      : "waitTime",
    "foodQuality",
    "service",
    "overAllExperience",
    "valueForMoney",
  ];

  const allQuestionsRated = questionsToRate.every(
    (question) => ratings[question] > 0
  );

  const handleGoBack = () => {
    navigate(`/`);
  };

  return (
    <Container maxWidth="sm" sx={{ padding: 3, mt: 16 }}>
      {checkAlreadyReviewExists ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ mt: 16, mb: 2, textAlign: "center" }}
          >
            You have already submitted the review for this order.
          </Typography>

          <LoadingButton
            fullWidth
            onClick={handleGoBack}
            size="large"
            type="submit"
            variant="contained"
            sx={{ mt: 3 }}
          >
            Go to Home
          </LoadingButton>
        </Box>
      ) : (
        <>
          <Typography
            variant="h3"
            gutterBottom
            sx={{ textAlign: "center", mb: 4, color: "#fca92e" }}
          >
            Review your Order
          </Typography>

          {isLoading ? (
            <LoadingScreen width="100px" height="100px" marginT="0" h="70vh" />
          ) : (
            <>
              <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                <Image
                  src={
                    venueImageURL ? venueImageURL : "/assets/placeholder.png"
                  }
                  alt={"Venue Image"}
                  style={{
                    height: "100px",
                    width: "140px",
                    borderRadius: "5px",
                  }}
                />
                <Box>
                  <Typography variant="h6">
                    {reviewExists?.venueId?.name}
                  </Typography>
                  <Typography>Order # {reviewExists?.billNumber}</Typography>
                  <Typography>
                    Total: Rs. {fNumber(reviewExists?.total)}
                  </Typography>
                </Box>
              </Box>

              <Divider sx={{ marginY: 2 }} />

              <Box>
                {questionsToRate.map((question) => (
                  <Box
                    key={question}
                    mb={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: {
                          xs: "14px",
                          sm: "16px",
                          md: "16px",
                        },
                      }}
                    >
                      {ratingMessages[question]}
                    </Typography>
                    <Box>{renderStars(question)}</Box>
                  </Box>
                ))}
                <Divider />
                <Box
                  mb={2}
                  mt={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: {
                        xs: "14px",
                        sm: "16px",
                        md: "16px",
                      },
                    }}
                  >
                    Want to review each item
                  </Typography>
                  <Switch
                    checked={itemWiseReview}
                    onChange={() => setItemWiseReview(!itemWiseReview)}
                    color="primary"
                  />
                </Box>
                {itemWiseReview && (
                  <Box>
                    {reviewExists?.items?.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",

                          "@media (max-width: 600px)": {
                            flexDirection: "column",
                            alignItems: "flex-start",
                          },
                          alignItems: "center",
                          gap: "10px",
                          mb: 1,
                        }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <Image
                            src={
                              imageURLs[index]
                                ? imageURLs[index]
                                : "/assets/placeholder.png"
                            }
                            alt={"Item Image"}
                            style={{
                              height: "50px",
                              width: "50px",
                              borderRadius: "5px",
                            }}
                          />
                          <Box>
                            <Typography sx={{ fontSize: "14px" }}>
                              {item?.qty} X {capitalizeWords(item?.name)}
                            </Typography>
                            {item?.groups && item?.groups?.length > 0 && (
                              <>
                                {item?.selectedVariant && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: 12,
                                        fontWeight: "bold",
                                        color: "#666",
                                      }}
                                    >
                                      Variant:
                                    </Typography>
                                    <Typography sx={{ ml: 1, fontSize: 12 }}>
                                      {item?.selectedVariant?.name}
                                    </Typography>
                                  </Box>
                                )}
                                <>
                                  {item?.groups?.map((sauce) => (
                                    <Typography
                                      key={index}
                                      variant="caption"
                                      sx={{
                                        color: "#666",
                                        display: "flex",
                                        gap: "2px",
                                        fontWeight: "bold",
                                        "@media (max-width: 600px)": {
                                          fontSize: "10px",
                                        },
                                      }}
                                    >
                                      {sauce?.name} :
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          color: "#666",
                                          display: "flex",
                                          marginLeft: "5px",
                                          gap: "2px",
                                          "@media (max-width: 600px)": {
                                            fontSize: "10px",
                                          },
                                        }}
                                      >
                                        {sauce?.items?.map((item, index) => (
                                          <span key={index}>
                                            {item?.item}
                                            {index !== sauce.items.length - 1 &&
                                              ", "}
                                          </span>
                                        ))}
                                      </Typography>
                                    </Typography>
                                  ))}
                                </>
                              </>
                            )}
                            <Typography sx={{ fontSize: "14px", color: "red" }}>
                              {item?.notes}
                            </Typography>

                            <Box
                              sx={{
                                display: { xs: "block", sm: "none" },
                                mt: 1,
                                ml: -0.5,
                              }}
                            >
                              <Box>{renderStars(item.id, true, item.id)}</Box>
                            </Box>
                          </Box>
                        </Box>

                        <Box sx={{ display: { xs: "none", sm: "block" } }}>
                          <Box>{renderStars(item.id, true, item.id)}</Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
                <TextField
                  label="Add a comment"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  sx={{ mb: 2, mt: 4 }}
                />
                <LoadingButton
                  onClick={handleSubmit}
                  color="primary"
                  variant="contained"
                  loading={isLoading}
                  disabled={!allQuestionsRated || !reviewExists}
                  sx={{
                    width: "100%",
                    height: "40px",
                    fontSize: "16px",
                  }}
                >
                  Submit
                </LoadingButton>
              </Box>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default ReviewPage;
