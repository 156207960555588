import {
  Box,
  Divider,
  Stack,
  Typography,
  Container,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Iconify from "src/components/iconify/Iconify";
import { truncateText } from "src/utils/truncateText";
import Image from "src/components/image/Image";
import { useRestaurantOpenClose } from "src/utils/useRestaurantOpenClose";
import { useSelector } from "react-redux";
import PromotionModal from "../promotion/PromotionModal";
import { StarIcon } from "src/theme/overrides/CustomIcons";

const VenueCover = ({ venuesCoverData }) => {
  const { currentDay, formatTime, venueStatus } = useRestaurantOpenClose();
  const { isLoggedIn } = useSelector((state) => state.login);

  const [promotionModal, setPromotionModal] = useState(false);
  const closeModal = () => {
    setPromotionModal(!promotionModal);
  };

  useEffect(() => {
    if (venuesCoverData?.detail?.venueTimings) {
      const currentTime = venuesCoverData?.detail?.venueTimings[currentDay];
      if (currentTime) {
        formatTime(currentTime, venuesCoverData?.detail);
      }
    }
  }, [venuesCoverData, currentDay]);

  if (!venuesCoverData) {
    return <div>Venue not found</div>;
  }

  // const displayTime = venueIsOpen ? showTime?.end : showTime?.start
  // const statusText = venueIsOpen ? "Open" : "Closed"
  // const actionText = venueIsOpen ? "Closes at " : "Open at"

  let { detail } = venuesCoverData ?? {};
  let { configurations } = detail ?? {};
  let { isPromotionAvailable } = configurations ?? {};

  const { promotion } = detail;

  const renderStars = (rating) => {
    return (
      <Box sx={{ display: "flex" }}>
        {[...Array(5)].map((_, index) => {
          const isFull = rating >= index + 1;
          const fraction = Math.min(Math.max(rating - index, 0), 1);

          return (
            <Box
              key={index}
              sx={{
                position: "relative",
                width: "20px",
                height: "20px",
              }}
            >
              <StarIcon
                style={{
                  color: "#ccc",
                  fontSize: "20px",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />

              <StarIcon
                style={{
                  color: "#fca92e",
                  fontSize: "20px",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  clipPath: isFull
                    ? "none"
                    : `polygon(0 0, ${fraction * 100}% 0, ${
                        fraction * 100
                      }% 100%, 0 100%)`,
                }}
              />
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          background: "#fff",
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            marginTop: "80px",
            width: "100%",
            height: "300px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            zIndex: "5",
            position: "relative",
            "@media (max-width: 768px)": {
              height: "220px",
              marginTop: "64px",
            },
            "@media (max-width: 500px)": {
              height: "120px",
              marginTop: "90px",
            },
            "@media (max-width: 400px)": {
              height: "110px",
              marginTop: "90px",
            },
          }}
        >
          <Stack
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <Image
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={
                venuesCoverData?.image
                  ? venuesCoverData?.image
                  : "/assets/placeholder.png"
              }
              alt=""
            />
          </Stack>
        </Box>
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: " #F08203",
                  fontWeight: "700",
                  marginLeft: "-1px",
                  marginTop: "5px",
                  fontSize: "35px",
                  zIndex: "40",
                  "@media (max-width: 600px)": {
                    fontSize: "25px",
                  },
                }}
              >
                {venuesCoverData?.detail?.name}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                  }}
                >
                  {venuesCoverData?.distance
                    ? (Number(venuesCoverData?.distance) / 1000).toFixed(1)
                    : 0}{" "}
                  km away
                </Typography>
              </Box>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  marginTop: "3px",
                  fontWeight: "400",
                  color: "#372F2F",
                  lineHeight: "26px",
                }}
              >
                <Iconify
                  sx={{
                    height: 20,
                    width: 30,
                    marginLeft: "-10px",
                  }}
                  icon="ion:location-outline"
                  color="#fca92e"
                />
                {`${
                  venuesCoverData?.detail?.venueAddressOne
                    ? truncateText(venuesCoverData?.detail?.venueAddressOne, 35)
                    : ""
                }${
                  venuesCoverData?.detail?.venueAddressTwo
                    ? `, ${truncateText(
                        venuesCoverData?.detail?.venueAddressTwo,
                        35
                      )}`
                    : ""
                }`}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  fontSize: "14px",
                  marginTop: "3px",
                  fontWeight: "400",
                  color: "#372F2F",
                  lineHeight: "26px",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: ["Closed", "Temporarily Closed"].includes(
                        venueStatus?.statusMessage
                      )
                        ? "red"
                        : "green",
                    }}
                    component="span"
                  >
                    {venueStatus?.statusMessage}
                  </Typography>
                  {(venueStatus?.additionalInfo == "Open at" ||
                    venueStatus?.additionalInfo == "Closes at") && (
                    <span style={{ padding: "0px 5px" }}>-</span>
                  )}
                  <Typography
                    sx={{ fontSize: "14px", marginRight: "5px" }}
                    component="span"
                  >
                    {venueStatus?.additionalInfo}
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }} component="span">
                    {venueStatus?.time}
                  </Typography>
                </Typography>
              </Box>

              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",

                  gap: "4px",
                }}
              >
                <Box sx={{ marginTop: "4px" }}>
                  {renderStars(venuesCoverData?.detail?.rating)}
                </Box>
                <Typography sx={{ fontSize: "14px" }}>
                  ({venuesCoverData?.detail?.rating})
                </Typography>
              </Box> */}
            </Box>
            {/* {isPromotionAvailable && (
              <Box>
                {!isLoggedIn ? (
                  <Typography
                    mt={2}
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: " #F08203",
                    }}
                  >
                    Sign up to avail these offers!
                  </Typography>
                ) : (
                  <Typography
                    mt={2}
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: " #F08203",
                    }}
                  >
                    Available Promotion
                  </Typography>
                )}
                {venuesCoverData?.detail?.promotion
                  ?.slice(0, 2)
                  ?.map((elem, index) => (
                    <Box
                      key={index}
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography
                        mt={1}
                        sx={{
                          fontWeight: "600",
                          color: "#333",
                          fontSize: "14px",
                          // color: " #F08203",
                        }}
                      >
                        {elem?.tag}
                      </Typography>
                    </Box>
                  ))}
                <Box mt={1}>
                  <Button
                    onClick={() => setPromotionModal(!promotionModal)}
                    variant="contained"
                    size="small"
                  >
                    more
                  </Button>
                </Box>
              </Box>
            )} */}
          </Box>
          <Divider
            sx={{
              marginTop: "10px",
              color: "#0000040",
              border: "1px solid #D3D3D3",
            }}
          />
        </Container>
      </Box>
      {promotionModal && (
        <PromotionModal
          width="sm"
          open={promotionModal}
          setPromotionModal={setPromotionModal}
          onClose={closeModal}
          promotion={promotion}
        />
      )}
    </>
  );
};

export default VenueCover;
