import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, FormHelperText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "../../components/snackbar";
import FormProvider, { RHFCodes } from "../../components/hook-form";
import { useEffect, useState } from "react";
import ResendCode from "src/pages/auth/ResendCode";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { verifyOTP } from "src/redux/slices/loginSlice";

export default function AuthVerifyCodeForm({
  handleVerified,
  updateIsCodeFormFilled,
  handleChangeVerificationPath,
  verificationPath,
  profilePath,
  isModal, 
  onCloseModal ,
  phoneNum,
  phone,
  phoneNumberForModal
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.login);

  const [apiError, setApiError] = useState("");

  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().required("Code is required"),
    code2: Yup.string().required("Code is required"),
    code3: Yup.string().required("Code is required"),
    code4: Yup.string().required("Code is required"),
  });

  const defaultValues = {
    code1: "",
    code2: "",
    code3: "",
    code4: "",
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
  } = methods;

  useEffect(() => {
    const subscription = watch(() => {
      setApiError(""); 
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const isFilled = Object.values(methods.getValues()).every(
      (value) => !!value
    );
    updateIsCodeFormFilled(isFilled);
  }, [methods.getValues(), updateIsCodeFormFilled]);

  const onSubmit = async (data) => {
    try {
      const otp = Object.values(data).join("");
      const getResgisteredUserId = localStorage.getItem("reg_Id");
      const apiData = {
        id: getResgisteredUserId || user?.id,
        otp: otp,
      };

      const queryParams = new URLSearchParams(window.location.search);
      const phoneNumber = queryParams.get("phoneNumber");

    if (verificationPath === "Phone" || isModal) {
      apiData.isPhone = "true";
      apiData.phone = phoneNumber?phoneNumber:phoneNum ? phoneNum : phoneNumberForModal ;
      } else {
        apiData.isEmail = "true";
      }
      const response = await dispatch(verifyOTP(apiData));
      if (response.status === 200) {
        localStorage.setItem("access_token", response.token);
        enqueueSnackbar("Verify success!");
        localStorage.removeItem("registered_userId");
        let queryParams = new URLSearchParams();
        queryParams.append('verify', verificationPath);

        if (!isModal) {
          navigate(`/auth/verifysuccess?${queryParams.toString()}`, { state: { profilePath , redirectedFromBack: true} });
        } else {
          onCloseModal();  
        }
        
        handleVerified(true);
      }
    } catch (error) {
      setApiError(error.response.data.message || "An error occurred. Please try again.");
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFCodes
          keyName="code"
          inputs={["code1", "code2", "code3", "code4"]}
        />

        {apiError ? (
          <FormHelperText error sx={{ px: 2 }}>
            {apiError}
          </FormHelperText>
        ) : (
          (!!errors.code1 || !!errors.code2 || !!errors.code3 || !!errors.code4) && (
            <FormHelperText error sx={{ px: 2 }}>
              OTP is required
            </FormHelperText>
          )
        )}
        
        <ResendCode
          verificationPath={verificationPath}
          handleChangeVerificationPath={handleChangeVerificationPath}
          phone={phone}
          phoneNumberForModal={phoneNumberForModal}
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ mt: 5, }}
        >
          Submit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
