import React, { useState, useMemo, useEffect } from "react";
import { PaymentElement, useXpay } from "@xstak/xpay-element";
import { PaymentElement as PaymentElementDevelopment, useXpay as useXpayDevelopment } from "@xstak/xpay-element-stage";
import { PaymentElement as PaymentElementProduction, useXpay as useXpayProduction } from "@xstak/xpay-element";
import api from "src/utils/axios";
import { useCart } from "./Venue/CartContext";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import { Box, Button, Stack, TextField } from "@mui/material";
import { useSnackbar } from "../components/snackbar";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { socket } from "src/App";
import { useDispatch } from "react-redux";
import { updateUserCardsInformation } from "src/redux/slices/loginSlice";
import CheckoutPaymentMethods from "./Venue/Checkout/CheckoutPaymentMethods";
import Iconify from "src/components/iconify/Iconify";
import { openVenueLocationModal, closeVenueLocationModal } from 'src/redux/slices/VenueLocationModalSlice';
import useGeolocation from "src/utils/geolocation";
import { resetVenueIdtoBlock } from "src/redux/slices/VenueLocationModalSlice";
import { clearTableData } from "src/redux/slices/tableSlice";
import { selectedVenue } from "src/utils/venueSelectionUtils";
import { getVenueListWithLocation } from "src/redux/slices/venueSlice";
import { fRound } from "src/utils/formatNumber";
import { useUserLocationEnabled } from "src/components/settings/UserLocationEnabled";


export const Payment = ({ method, onClose, total, serviceFee,
  taxAmount, error, setError, setOpenCart, setIsDisabled, discount, appliedPromotion }) => {
  const { triggerLocation } = useGeolocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { cardItems } = useSelector((state) => state.cardSlice);
  const { selectedTip, calculateSubTotal, isApplicable } = useCart();
  const { user, isLoggedIn } = useSelector((state) => state.login);
  let mode = localStorage.getItem("mode");
  const guestUserData = cardItems?.data?.customer;
  const { tableData } = useSelector((state) => state.tableData);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [binDetails, setBinDetails] = useState(null);
  const [filledAllCardDetails, setFilledAllCardDetails] = useState(false);
  const [saveCardInfo, setSaveCardInfo] = useState(false);

  const [cardNetwork, setCardNetwork] = useState("");
  const [cardLastDigits, setCardLastDigits] = useState("");
  const [cardStartDigits, setCardStartDigits] = useState("");
  const [selectedCardOption, setSelectedCardOption] = useState(null);
  const { data } = cardItems ?? {};
  const { venueDetail } = data ?? {}
  const { serviceFeesObject, configurations } = venueDetail ?? {}
  const { isServiceFeesOnWeb } = configurations ?? {}
  const { getUserCurrentLocation } = useUserLocationEnabled();

  const handleOpenModal = () => dispatch(openVenueLocationModal());

  useEffect(() => {
    if (user?.savedCardsInformation?.length > 0) {
      setSelectedCardOption(0)
    }
  }, [user]);

  const subTotal = useMemo(
    () =>
      cardItems?.data?.items?.length > 0
        ? calculateSubTotal(cardItems.data.items)
        : 0,
    [cardItems]
  );

  const handleChangeSavedCards = (index) => {
    setSelectedCardOption(index);
  };


  const handleCardDelete = async (id) => {
    try {
      const response = await api.delete(`${process.env.REACT_APP_BACKEND_URL}/payment/deleteToken?tokenId=${id}&userId=${user.id}`);
      if (response.data.customer) {
        await dispatch(updateUserCardsInformation(response.data.customer?.savedCardsInformation));
      }
    } catch (error) {
      enqueueSnackbar("Error In Deletion Of Card!", { variant: "error" });
    }
  };

  const options = {
    override: true,
    fields: {
      creditCard: {
        placeholder: "4234 1234 1234 1234",
        label: "Enter your credit card",
      },
      exp: {
        placeholder: "Exp. Date",
      },
    },
    style: {
      ".input": {
        border: "1px solid black",
        "border-radius": "15px",
      },
      ".invalid": {},
      ".label": {
        "font-size": "18px",
      },
      ":focus": {},
      ":hover": {},
      "::placeholder": {},
      "::selection": {},
    },
  };


  const handleChange = (event) => {
    setSaveCardInfo(event.target.checked);
  };


  const pay = async () => {
    setIsLoading(true);
    let totalServiceValue = 0;

    if (isServiceFeesOnWeb && isApplicable(serviceFeesObject?.[mode]?.[method]?.applicable)) {
      totalServiceValue = Number(serviceFee);
    }

    let serviceFeesObj = null
    if (isServiceFeesOnWeb && isApplicable(serviceFeesObject?.[mode]?.[method]?.applicable)) {
      serviceFeesObj = {
        [mode]: {
          [method]: serviceFeesObject?.[mode]?.[method]
        }
      };
    }
    let location
    if (getUserCurrentLocation) {
      location = await triggerLocation(handleOpenModal)
    }

    try {
      const customer1 = isLoggedIn ? { name: `${user?.firstName} ${user?.lastName}`, email: user?.email, phone: user?.phone } : { name: cardItems?.data?.customer?.name, email: cardItems?.data?.customer?.email, phone: cardItems?.data?.customer?.phone }
      const shipping = {
        address1: "abc",
        city: "Islamabad",
        country: "Pakistan",
        phone: "03415555555",
        zip: "12345",
        shipping_method: "Standard",
      };
      let dataForCreateIntent = {
        amount: fRound(total, 2),
        currency: "PKR",
        payment_method_types: "card",
        customer: customer1,
        shipping: shipping,
        customerId: user?.id,
        levelId: cardItems?.data?.levelId,
        venueId: cardItems?.data?.venueId,
        total: fRound(total, 2),
        subTotal: fRound(subTotal, 2),
        type: "web",
        sessionId: cardItems?.data?.sessionId,
        orderType: mode,
        tableId: tableData?.id,
        tip: selectedTip === null ? 0 : fRound(selectedTip, 2),
        paymentType: method,
        tax: fRound(taxAmount, 2),
        location: location,
        saveCardInfo: saveCardInfo,
        platformFees: 9.9,
      }

      if (location) {
        dataForCreateIntent.location = location
      }
      if (isServiceFeesOnWeb && serviceFeesObj !== null) {
        dataForCreateIntent.serviceFees = fRound(totalServiceValue, 2)
        dataForCreateIntent.serviceFeesObject = serviceFeesObj
      }
      if (appliedPromotion) {
        dataForCreateIntent.promotion =
        {
          id: appliedPromotion?.id,
          amountType: appliedPromotion?.amountType,
          amount: appliedPromotion?.amount,
          minimumOrderAmount: appliedPromotion?.minimumOrderAmount,
          cappedAmount: appliedPromotion?.cappedAmount,
          discountAvailed: discount,
          type: appliedPromotion?.type
        }
      }
      if (selectedCardOption != null) {
        dataForCreateIntent.payment_method_types = "token";
        dataForCreateIntent.tokenId = user?.savedCardsInformation[selectedCardOption]._id
        dataForCreateIntent.userId = user.id
      } else {
        dataForCreateIntent.card = { cardNetwork: cardNetwork, cardLastDigits: cardLastDigits, cardStartDigits: cardStartDigits }
      }
      const paymentIntentResponse = await api.post(
        `${process.env.REACT_APP_BACKEND_URL}/payment/createIntent`, dataForCreateIntent
      );
      if (paymentIntentResponse.data.response.success == true) {
        const { pi_client_secret, encryptionKey } =
          paymentIntentResponse.data.response.data;
        try {
          let message = null;
          let error = null;
          if (selectedCardOption == null) {
            const result = await xpay.confirmPayment(
              "card",
              pi_client_secret,
              customer1,
              encryptionKey
            );
            message = result.message;
            error = result.error;
          }
          if (error) {
            setIsLoading(false);
            enqueueSnackbar("Payment Failed!");
            throw new Error(`Payment failed!: ${error}`);
          } else if (selectedCardOption == null || !error) {

            let updateOrderData = {
              orderId: paymentIntentResponse.data.order.id,
              cartId: paymentIntentResponse.data.order.cartId,
              paymentIntent: paymentIntentResponse.data.response.data._id,
              transactionId: paymentIntentResponse.data.transaction.id,
              saveCardInfo: saveCardInfo,
            }

            if (saveCardInfo) {
              updateOrderData.cardNetwork = cardNetwork;
              updateOrderData.cardLastDigits = cardLastDigits
              updateOrderData.cardStartDigits = cardStartDigits
            }
            const updateOrderResponse = await api.post(
              `${process.env.REACT_APP_BACKEND_URL}/cart/updateOrderStateOnPayment`,
              updateOrderData
            );
            if (updateOrderResponse.error) {
              setIsLoading(false);
              enqueueSnackbar("Payment Failed!", { variant: "error" });
              throw new Error(`Order update failed: ${updateOrderResponse.error}`);
            } else {

              if (mode == 'QrDineIn' || mode == 'QrPickUp' || mode == 'carHop') {
                dispatch(resetVenueIdtoBlock())
                localStorage.setItem("mode", "dineIn");
                dispatch(clearTableData());
                localStorage.removeItem("venueId")
                await dispatch(getVenueListWithLocation(location))
              }

              setIsLoading(false);
              socket.emit("addIncomingOrder", { order: updateOrderResponse.data.order });
              if (updateOrderResponse.data.order.orderType == "QrDineIn") {
                socket.emit("addToTables", {
                  table: { ...updateOrderResponse.data.order.tableId, isSuperAdmin: false },
                });
              }
              if (updateOrderResponse.data.customer) {
                await dispatch(updateUserCardsInformation(updateOrderResponse.data.customer?.savedCardsInformation));
              }

              navigate("/active");
            }
          }
        } catch (error) {
          await api.post(
            `${process.env.REACT_APP_BACKEND_URL}/payment/paymentFailed`,
            {
              orderId: paymentIntentResponse.data.order.id,
              cartId: paymentIntentResponse.data.order.cartId,
              transactionId: paymentIntentResponse.data.transaction.id,
              message: error.message
            }
          );
          enqueueSnackbar("Payment Failed!", { variant: "error" });
          setIsLoading(false);
          setError(error?.response?.data?.message);
          await dispatch(clearTableData());
          if (mode == "QrDineIn" && error?.response?.data?.message == 'Table is Not Available') {
            dispatch(resetVenueIdtoBlock())
            localStorage.setItem("mode", "dineIn");
            setOpenCart(true);
          }

          if (error.response?.data?.error?.status != 400 && (error.error && error.error != true)) {
            setIsDisabled(true);

          }
          if (error?.response?.data?.message == "Sorry,this venue is not taking orders at the moment. Please try again later.") {
            await selectedVenue(dispatch, mode, true);
            localStorage.setItem("VenueNotAvailableMessageSeen", false);
            const errorMessage = "Venue Not Available";
            navigate(`/venue`, { replace: true, state: { errorMessage } });
          }
        }
      } else {
        setIsLoading(false);
        enqueueSnackbar("Payment Failed!", { variant: "error" });
        throw new Error("Payment Intent creation failed");
      }
    } catch (error) {
      setError(error?.response?.data?.message);
      await dispatch(clearTableData());
      if (mode == "QrDineIn" && error?.response?.data?.message == 'Table is Not Available') {
        dispatch(resetVenueIdtoBlock())
        localStorage.setItem("mode", "dineIn");
        setOpenCart(true);
      }
      if (error.response?.data?.error?.status != 400) {
        setIsDisabled(true);
      }
      if (error?.response?.data?.message == "Sorry,this venue is not taking orders at the moment. Please try again later.") {
        await selectedVenue(dispatch, mode, true);
        localStorage.setItem("VenueNotAvailableMessageSeen", false);
        const errorMessage = "Venue Not Available";
        navigate(`/venue`, { replace: true, state: { errorMessage } });
      }

      console.error("Error during payment process:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };


  const useXpay = process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === "staging" ? useXpayProduction : useXpayDevelopment;
  const PaymentElement = process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === "staging" ? PaymentElementProduction : PaymentElementDevelopment;
  const xpay = useXpay();

  return (
    <>
      <Box p={2}>
        {" "}

        <div>
          {/* <CheckoutPaymentMethods/> */}



          <Stack
            alignItems="flex-start"
            sx={{
              // px: 3,
              width: 1,
            }}
          >
            {user?.savedCardsInformation?.length > 0 && selectedCardOption !== null &&
              <TextField
                select
                fullWidth
                label="Card"
                sx={{ marginBottom: selectedCardOption != null ? "0" : "20px" }}
                SelectProps={{
                  native: true,
                }}
              >
                {user?.savedCardsInformation?.map((card, index) => (
                  <option key={card.value} value={index}
                    checked={selectedCardOption === index}
                    onClick={() => { handleChangeSavedCards(index) }}>
                    **** **** **** {card.lastDigits}
                  </option>
                ))}
              </TextField>
            }
            {(selectedCardOption != null) &&
              <Button
                size="small"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => { setSelectedCardOption(null) }}
                sx={{ my: 3 }}
              >
                Add new card
              </Button>
            }
            {(user?.savedCardsInformation?.length > 0 && selectedCardOption == null) &&
              <Button
                size="small"
                onClick={() => { setSelectedCardOption(0) }}
                sx={{ mb: 2 }}
              >
                use save card
              </Button>
            }
          </Stack>


          {/* 
           {user?.savedCardsInformation?.length > 0 && user?.savedCardsInformation?.map((option, index) => (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
              <label key={index} sx={{}}>
                <input
                  type="radio"
                  value={index}
                  checked={selectedCardOption === index}
                  onChange={() => { handleChangeSavedCards(index) }}
                />
                *************{option.lastDigits} 
              <Button onClick={()=>{ handleCardDelete(option._id) }}>Delete Card</Button>
              </label>
            </Box>
          ))}   */}

        </div>
        {/* {(selectedCardOption != null) &&
          <Button
            variant="contained"
            color="error"
            onClick={() => { setSelectedCardOption(null) }}
          >
            Add New Card
          </Button>
        } */}
        {
          (selectedCardOption == null) &&
          <PaymentElement
            options={options}
            onBinDiscount={(event) => {
              setBinDetails(event.data);
              setCardLastDigits(event.data.last4digts)
              setCardStartDigits(event.data.bin)
              setCardNetwork(event.data.card_network)
              console.log("bin details", event);
            }}
            onReady={(event) => {
              setFilledAllCardDetails(event.ready);
              console.log("ready event", event);
            }}
          />
        }
        {(filledAllCardDetails || (selectedCardOption != null)) && (
          <LoadingButton
            fullWidth
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isLoading}
            onClick={pay}
            disabled={!user?.isPhoneVerified && isLoggedIn}
            sx={{
              bgcolor: "#fda92d",
              color: (theme) =>
                theme.palette.mode === "black" ? "common." : "grey.800",
              "&:hover": {
                bgcolor: "#B66816",
                color: (theme) =>
                  theme.palette.mode === "black" ? "common.white" : "grey.800",
              },
            }}
          >
            Pay Now
          </LoadingButton>
        )}

        {isLoggedIn && filledAllCardDetails &&
          <FormControlLabel
            control={
              <Checkbox
                checked={saveCardInfo}
                onChange={handleChange}
                name="myCheckbox"
                color="primary"
                sx={{ marginLeft: '2px' }}
              />
            }
            label="Save the Card For Future Use!"
          />
        }

      </Box>
    </>
  );
};
//comment for testing
