import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

const VenueProfilesGuard = ({ children }) => {
  const { venueId } = useParams();
  const navigate = useNavigate();
  const { venues } = useSelector((state) => state.venues);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/venues/b2c/${venueId}`);
      } catch (error) {
        if (error.response?.data?.message == 'Venue not found' || error.response?.data?.message.includes("Cast to ObjectId failed")) {
          navigate('/404');
          return
        }
        console.log(error);
      }
    };
    fetchData()
  }, [venueId, venues, navigate]);

  return children;
};

export default VenueProfilesGuard;