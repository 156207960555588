import { createSlice } from "@reduxjs/toolkit";
import api from "src/utils/axios";

const initialState = {
    isLoading: false,
    error: null,
    promotions: [],
};

const promotionSlice = createSlice({
    name: "promotions",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = false;
        },
        getPromotionsSuccess(state, action) {
            state.isLoading = false;
            state.promotions = action.payload;
            state.error = null;
        },
        getPromotionsFailer(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

export default promotionSlice.reducer;

export const { startLoading, getPromotionsSuccess, getPromotionsFailure } = promotionSlice.actions;

export function getPromotionsByOrderType(venueId, customerId, type, venuePermissions) {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const { configurations } = venuePermissions ?? {};
            const { isCashAvailableOnCarHop, isCashAvailableOnPickUp, isCashAvailableOnTableScan, isCashAvailableOnVenueScan
            } = configurations ?? {};
            let paymentMethodType;
            if ((type === 'carHop' && isCashAvailableOnCarHop===false) || (type === 'pickUp' && isCashAvailableOnPickUp===false) ||
                (type === 'QrDineIn' && isCashAvailableOnTableScan===false) || (type === 'QrPickUp' && isCashAvailableOnVenueScan===false)
            ) {
                paymentMethodType = 'card';
            }
            const params = {
                venueId,
                customerId,
                orderType: type,
                isActive: true,
                isDeleted: false,
                origin: { web: true },
            };

            if (paymentMethodType) {
                params.paymentMethodType = paymentMethodType;
            }
            const response = await api.get(
                `${process.env.REACT_APP_BACKEND_URL}/promotion/forCustomer`,
                { params }
            );
            dispatch(getPromotionsSuccess(response?.data?.results));
            return response;
        } catch (error) {
            dispatch(getPromotionsSuccess([]));
            return error;
        }
    };
}

