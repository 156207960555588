import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import VenueHeader from "../Venue/VenueHeader";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useSession from "src/utils/useSession";
import {
  deleteCartBySessionId,
  getCartByCustomerId,
} from "src/redux/slices/cardSlice";
import { clearTableData } from "src/redux/slices/tableSlice";
import axios from "axios";
import { Box } from "@mui/material";
import FooterLogin from "src/layouts/compact/FooterLogin";

const QrVenueScan = () => {
  const { venueId } = useParams();
  const navigate = useNavigate();
  const sessionInfo = useSession();

  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const { cardItems } = useSelector((state) => state?.cardSlice);

  const RemoveItemFromCart = async (data) => {
    try {
      if (data?.items?.length > 0) {
        let res = await dispatch(deleteCartBySessionId(sessionInfo?.sessionId));
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(clearTableData());
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/venues/b2c/${venueId}`);
        if(response.data.configurations.isQrVenueScanAvailable == false){
          setMessage("Sorry, this venue is not accepting orders from QR Pickup at the moment. Please try again later.");
        }
         else if (response.data.status == 'inActive') {
          setMessage("Sorry, this venue is not available at the moment.Please try again later.");
        } else {
          const response = await dispatch(getCartByCustomerId(sessionInfo?.sessionId));
          if (response?.data?.items?.length > 0 && response?.data?.venueId != venueId) {
            let res = await RemoveItemFromCart(response?.data);
            if (res?.status === 204) {
              localStorage.setItem("mode", "QrPickUp");
              localStorage.setItem("venueId", venueId);
              const changeMode = "QrPickUp"
              navigate(`/venue/${venueId}`, { state: { changeMode } });
            }
          } else {
            localStorage.setItem("mode", "QrPickUp");
            localStorage.setItem("venueId", venueId);
            const changeMode = "QrPickUp"
            navigate(`/venue/${venueId}`, { state: { changeMode } });
          }
        }
      } catch (error) {
        if (error.response?.data?.message == 'Venue not found' || error.response?.data?.message.includes("Cast to ObjectId failed")) {
          navigate(`/`);
          return
        }
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Egora-QR Code</title>
      </Helmet>
      <VenueHeader />
      <Box
        mt={20}
        sx={{
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: "40px",
        }}
      >
        {message}
      </Box>
      <FooterLogin />
    </div>
  );
};

export default QrVenueScan;
