import React, { useCallback, useEffect } from "react";
import { useCart } from "../CartContext";
import { useSwipeable } from "react-swipeable";

import {
    Box,
    Button,
    Divider,
    Stack,
    Typography,
} from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    updateItemsToCard,
} from "src/redux/slices/cardSlice";
import Image from "src/components/image/Image";
import useSession from "src/utils/useSession";
import { fNumber } from "src/utils/formatNumber";
import { fetchImage } from "src/utils/fetchImage";
import capitalizeWords from "src/utils/capitalizeWords";


const CartItems = ({ cartItem, index, showDeleteIndex, setShowDeleteIndex, handleRemoveFromCart, handleMenuItemClick }) => {
    const sessionInfo = useSession();
    const dispatch = useDispatch();
    const { cardItems } = useSelector((state) => state.cardSlice);
    const {
        calculateMenuItemTotal,
    } = useCart();


    const [imageStyle, setImageStyle] = useState({});

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            setShowDeleteIndex(index);
            setImageStyle({ transform: "translateX(-30px)", transition: "transform 0.3s ease" });
        },
        onSwipedRight: () => {
            setShowDeleteIndex(null);
            setImageStyle({ transform: "translateX(0)", transition: "transform 0.3s ease" });
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });


    const [imageURLs, setImageURLs] = useState([]);

    useEffect(() => {
        const fetchImagesForCart = async () => {
            if (cardItems?.data?.items && cardItems?.data?.items.length > 0) {
                const urls = await Promise.all(
                    cardItems?.data?.items.map(async (elem) => {
                        try {
                            const res = await fetchImage(elem.photoURL);
                            return res;
                        } catch (error) {
                            console.error("Error fetching data:", error);
                            return null;
                        }
                    })
                );
                setImageURLs(urls);
            }
        };

        fetchImagesForCart();
    }, [cardItems]);

    const updateItemFromCardAddByOne = useCallback(async (item) => {
        try {
            if (item.qty < 1000) {
                const updatedItem = {
                    ...item,
                    qty: item.qty + 1,
                };
                await dispatch(updateItemsToCard(updatedItem, sessionInfo?.sessionId));
            } else {
                console.log("Maximum limit of 1000 reached");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, []);

    const updateItemFromCardDecByOne = useCallback(async (item) => {
        try {
            const updatedItem = {
                ...item,
                qty: item.qty - 1,
            };
            await dispatch(updateItemsToCard(updatedItem, sessionInfo?.sessionId));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, []);


    return (
        <Box>
            <>
                <Box
                    {...handlers}
                    key={index}
                    sx={{
                        ...imageStyle,
                        display: "flex",
                        padding: "10px 0",
                        justifyContent: "space-between",
                        alignItems: "start",
                        borderRadius: "5px",
                        width: "100%",
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "flex-start",
                            }}
                            onClick={() => handleMenuItemClick(cartItem)}
                        >
                            <Box
                                sx={{

                                    display: "inline-block",
                                    padding: "5px",
                                    borderRadius: "8px",
                                    "@media (max-width: 600px)": {
                                        padding: "3px",
                                    },
                                }}
                            >
                                <Image
                                    src={
                                        imageURLs[index]
                                            ? imageURLs[index]
                                            : "/assets/placeholder.png"
                                    }
                                    sx={{

                                        height: "80px",
                                        width: "80px",
                                        borderRadius: "5px",
                                        "@media (max-width: 600px)": {
                                            height: "50px",
                                            width: "50px",
                                        },
                                    }}
                                />
                            </Box>

                            <Stack>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "self-end",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <Typography
                                        onClick={() => handleMenuItemClick(cartItem)}
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: "14px",
                                            marginLeft: "9px",
                                            cursor: "pointer",
                                            "@media (max-width: 600px)": {
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                width:"80px"
                                            },

                                            "@media (max-width: 330px)": {
                                                maxWidth: "100px",
                                            },
                                        }}
                                    >
                                        {capitalizeWords(cartItem?.name)}
                                    </Typography>
                                    <img
                                        src="/assets/order/edit.svg"
                                        alt="Dine In"
                                        style={{
                                            marginLeft: "7px",
                                            color: "#F08203",
                                            cursor: "pointer",
                                            width: "12px",
                                            marginBottom: "3px",
                                        }}
                                        onClick={() => handleMenuItemClick(cartItem)}
                                    />
                                </Box>

                                {cartItem?.selectedVariant && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                ml: 1.3,
                                                fontSize: 12,
                                                fontWeight: "bold",
                                                color: "#666",
                                            }}
                                        >
                                            Variant:
                                        </Typography>
                                        <Typography sx={{ ml: 1, fontSize: 12 }}>
                                            {cartItem?.selectedVariant?.name}
                                        </Typography>
                                    </Box>
                                )}

                                {cartItem?.groups && cartItem?.groups?.length > 0 && (
                                    <>
                                        {cartItem?.groups?.map((sauce) => (
                                            <Typography
                                                key={index}
                                                variant="caption"
                                                sx={{
                                                    color: "#666",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    flexDirection: "row",
                                                    marginLeft: "10px",
                                                    gap: "2px",
                                                    fontWeight: "bold",
                                                    "@media (max-width: 600px)": {
                                                        fontSize: "10px",
                                                        marginLeft: "5px",
                                                        gap: "1px",
                                                    },
                                                }}
                                            >
                                                + {sauce?.name} :
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        color: "#666",
                                                        display: "flex",
                                                        marginLeft: "5px",
                                                        gap: "2px",
                                                        flexWrap: "wrap",
                                                        "@media (max-width: 600px)": {
                                                            fontSize: "10px",
                                                            marginLeft: "2px",
                                                            gap: "1px",
                                                        },
                                                    }}
                                                >
                                                    {sauce?.items?.map((sauceItem, sauceIndex) => (
                                                        <span key={sauceIndex}>
                                                            {sauceItem?.item}
                                                            {` (Rs. ${fNumber(
                                                                sauceItem.price * cartItem.qty
                                                            )})`}
                                                            {sauceIndex !== sauce?.items?.length - 1 &&
                                                                ", "}
                                                        </span>
                                                    ))}
                                                </Typography>
                                            </Typography>
                                        ))}
                                    </>
                                )}
                                <Typography
                                    variant="caption"
                                    sx={{
                                        display: "flex",
                                        marginLeft: "10px",
                                        gap: "2px",

                                        "@media (max-width: 600px)": {
                                            fontSize: "10px",
                                            "@media (max-width: 600px)": {
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                maxWidth: "80px",
                                            },
                                        },
                                    }}
                                >
                                    <span>{cartItem?.notes}</span>
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#F08203",
                                        marginTop: "4px",
                                        fontSize: "16px",
                                        marginLeft: "10px",
                                        fontWeight: "600",
                                        "@media (max-width: 1440px)": {
                                            fontSize: "12px",
                                        },
                                    }}
                                >
                                    {calculateMenuItemTotal(cartItem)}
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "self-end",
                            flexWrap: "nowrap",
                        }}
                    >
                        <Button
                            sx={{
                                height: "40px",
                                width: "130px",
                                borderRadius: "20px",
                                border: "2px solid #F08203",
                                marginRight: "10px",
                                "@media (max-width: 600px)": {
                                    width: "100px",
                                    height: "35px",
                                    marginRight: "5px",
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                {cartItem.qty > 1 ? (
                                    <Button
                                        onClick={() => updateItemFromCardDecByOne(cartItem)}
                                        sx={{
                                            minWidth:cartItem.qty >= 100 ? "42px":"64px",
                                        }}
                                    >
                                        <Iconify
                                            sx={{
                                                color: "#F08203",
                                            }}
                                            icon={"ic:baseline-minus"}
                                        />
                                    </Button>
                                ) : (
                                    <Button onClick={() => handleRemoveFromCart(cartItem)}>
                                        <Iconify
                                            sx={{
                                                color: "#F08203",
                                            }}
                                            icon={"mdi:trash-outline"}
                                        />
                                    </Button>
                                )}
                                <Typography sx={{ fontSize: "16px", color: "#F08203" }}>
                                    {cartItem.qty}
                                </Typography>
                                <Button
                                    onClick={() => updateItemFromCardAddByOne(cartItem)}
                                    sx={{
                                        minWidth:cartItem.qty >= 100 ? "42px":"64px",
                                    }}
                                >
                                    <Iconify
                                        sx={{
                                            color: "#F08203",
                                        }}
                                        icon={"ic:baseline-plus"}
                                    />
                                </Button>
                            </Box>
                        </Button>
                        {(showDeleteIndex === index && cartItem.qty > 1) && (
                            <Button
                                onClick={() => handleRemoveFromCart(cartItem)}
                                sx={{
                                    minWidth: 0,
                                    margin: "0",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingButton: "5px",
                                    paddingLeft: "20px",
                                    paddingRight: "0px"

                                }}
                            >
                                <Iconify
                                    sx={{
                                        color: "#F08203",
                                        minWidth: 0,
                                        width: "24px",
                                        height: "24px",
                                    }}
                                    icon={"mdi:trash-outline"}
                                />
                            </Button>
                        )}
                    </Box>
                </Box>

                {index < cardItems.data.items.length - 1 && (
                    <Divider
                        sx={{
                            marginRight: "13px",
                            marginLeft: "8px",
                            marginTop: "10px",
                            borderColor: "#f0820373",
                        }}
                    />
                )}
            </>

        </Box>
    );
};

export default CartItems;
