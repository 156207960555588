import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fNumber } from "src/utils/formatNumber";

const CartContext = createContext();

export function CartProvider({ children }) {
  const [cart, setCart] = useState([]);
  const [isCartOpen, setCartOpen] = useState(false);
  const { cardItems } = useSelector((state) => state.cardSlice);

  const [selectedVenue, setSelectedVenue] = useState(null);
  const [selectedTip, setSelectedTip] = useState(
    cardItems?.data?.tip ? cardItems?.data?.tip : 0
  );

  useEffect(() => {
    if (cardItems?.data?.tip > 0) setSelectedTip(cardItems?.data?.tip);
  }, [cardItems]);

  const openCart = () => {
    setCartOpen(true);
  };

  const closeCart = () => {
    setCartOpen(false);
  };

  const toggleCart = () => {
    setCartOpen((prevOpen) => !prevOpen);
  };

  const selectVenue = (venue) => {
    setSelectedVenue(venue);
  };

  const addToCart = (item, quantity, selectedSauces, selectedAddOns) => {
    const existingCartItem = cart.find(
      (cartItem) => cartItem.title === item.title
    );

    if (existingCartItem) {
      const updatedCart = cart.map((cartItem) =>
        cartItem.title === item.title
          ? {
            ...cartItem,
            quantity: cartItem.quantity + quantity,
            selectedSauces: selectedSauces || cartItem.selectedSauces,
            selectedAddOns: selectedAddOns || cartItem.selectedAddOns,
          }
          : cartItem
      );

      setCart(updatedCart);
    } else {
      setCart([
        ...cart,
        {
          ...item,
          quantity,
          selectedSauces: selectedSauces || [],
          selectedAddOns: selectedAddOns || [],
        },
      ]);
    }
  };

  const removeFromCart = (itemId) => {
    setCart((prevCart) => prevCart.filter((item) => item.title !== itemId));
  };

  const handleTipChange = (tipValue) => {
    setSelectedTip(tipValue);
  };
  const resetCart = () => {
    setSelectedTip(0);
  };

  const calculateMenuItemTotal = (cartItem) => {
    const itemQuantity = cartItem.qty;
    let p =
      cartItem?.choiceGroup?.length > 0 || cartItem?.variants?.length > 0
        ? cartItem?.priceWithChoiceGroup
        : cartItem?.price;
    let itemTotal = p * itemQuantity;
    if (cartItem.selectedAddOns && cartItem.selectedAddOns.length > 0) {
      cartItem.selectedAddOns.forEach((addon) => {
        itemTotal += parseFloat(addon.price.replace("Rs. ", ""));
      });
    }
    itemTotal = itemTotal.toFixed(0);
    return `Rs. ${fNumber(itemTotal)}`;
  };

  const calculateSubTotal = (cart) => {
    const grandTotal = cart.reduce((total, cartItem) => {
      const itemQuantity = cartItem.qty;
      let itemTotal =
        parseFloat(
          cartItem.priceWithChoiceGroup
            ? cartItem.priceWithChoiceGroup
            : cartItem.price
        ) * itemQuantity;
      if (cartItem.selectedAddOns && cartItem.selectedAddOns.length > 0) {
        cartItem.selectedAddOns.forEach((addon) => {
          itemTotal += parseFloat(addon.price.replace("Rs. ", ""));
        });
      }
      return total + itemTotal;
    }, 0);
    return `${grandTotal.toFixed(0)}`;
  };

  const calculateFinalTotal = (cart, tip, discount) => {
    const itemsTotal = cart.reduce((total, cartItem) => {
      const itemQuantity = cartItem.qty;
      let itemTotal =
        parseFloat(
          cartItem.priceWithChoiceGroup
            ? cartItem.priceWithChoiceGroup
            : cartItem.price
        ) * itemQuantity;

      if (cartItem.selectedAddOns && cartItem.selectedAddOns.length > 0) {
        cartItem.selectedAddOns.forEach((addon) => {
          itemTotal += parseFloat(addon.price.replace("Rs. ", ""));
        });
      }

      let fTotal = (Number(total) + Number(itemTotal)) - Number(discount)
      return fTotal;
    }, 0);

    const grandTotal = itemsTotal + parseFloat(selectedTip > 0 ? selectedTip : tip > 0 ? tip : 0);
    return `${grandTotal.toFixed(0)}`;
  };

  const calculateServiceFee = (
    cardItems,
    mode,
    paymentOption,
    subTotal,
    discount,
  ) => {
    const { data } = cardItems ?? {};
    const { venueDetail } = data ?? {};
    const { serviceFeesObject, configurations } = venueDetail ?? {};
    const { isServiceFeesOnWeb, isCashAvailableOnPickUp, isCashAvailableOnCarHop, isCashAvailableOnTableScan, isCashAvailableOnVenueScan,
    } = configurations ?? {};

    if (!isServiceFeesOnWeb) return 0;
    const modeCashAvailability = {
      pickUp: isCashAvailableOnPickUp,
      QrDineIn: isCashAvailableOnTableScan,
      QrPickUp: isCashAvailableOnVenueScan,
      carHop: isCashAvailableOnCarHop,
    };

    if (!modeCashAvailability[mode] && paymentOption === "cash") {
      return 0;
    }
    const modeData = serviceFeesObject?.[mode];
    if (!modeData) return 0;
    const paymentData = modeData[paymentOption];
    if (paymentData?.applicable !== "true") return 0;
    let serviceFeeableSubtotal = subTotal - discount;
    if (paymentData?.type === "Percentage") {
      return (serviceFeeableSubtotal * parseFloat(paymentData.amount)) / 100;
    } else {
      return parseFloat(paymentData.amount);
    }
  };

  const isApplicable = (applicable) => {
    return applicable === "true" || applicable === true;
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        isCartOpen,
        openCart,
        closeCart,
        toggleCart,
        selectVenue,
        selectedVenue,
        selectedTip,
        handleTipChange,
        calculateMenuItemTotal,
        calculateFinalTotal,
        calculateSubTotal,
        resetCart,
        calculateServiceFee,
        isApplicable,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  return useContext(CartContext);
}

