import { Box, Typography } from "@mui/material";
import React from "react";

import verified from "../../../../assets/icons/Verifiedtick.svg"
import { LoadingButton } from "@mui/lab";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import VenueHeader from "../../../Venue/VenueHeader";
import useHandleDirectRoute from 'src/hooks/useHandleDirectRoute';
const ReviewSuccess = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(`/`);
  };
  useHandleDirectRoute(location.state?.redirectedFromBack)
  const from = location.state?.from || "/";
  return (
    <>
      <VenueHeader />
      <Container maxWidth="sm" sx={{ padding: 3, mt: 16 }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="5vh"
        >
          <img src={verified} alt="verification" />
        </Box>
        <Typography variant="h3" paragraph sx={{ textAlign:"center", mt:2}}>
          Submitted
        </Typography>

        <Typography sx={{ color: "text.secondary", mb: 2 , textAlign:"center"}}>
         Thank you for reviewing your order.
        </Typography>
        <LoadingButton
          fullWidth
          onClick={handleGoBack}
          // component={RouterLink}
          // to={'/venue'}
          size="large"
          type="submit"
          variant="contained"
          // loading={isSubmitting}
          sx={{ mt: 3 }}
        >
          Go to Home
        </LoadingButton>
      </Container>
    </>
  );
};

export default ReviewSuccess;
