import { useRoutes } from "react-router-dom";
import AuthGuard from "../auth/AuthGuard";
import CompactLayout from "../layouts/compact";

import {
  SearchMainPage,
  PaymentCustomPage,
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  CheckEmail,
} from "./elements";
// import SearchMainPage from "src/pages/SearchMainPage";
import VenueMainPage from "src/pages/Venue/VenueMainPage";
import VenueProfiles from "src/pages/Venue/Venue Profiles/VenueProfiles";
import CartCheckout from "src/pages/Venue/Checkout/CartCheckout";
import OrdeHistoryPage from "src/pages/Venue/order/OrdeHistoryPage";
import GuestReviewPage from "src/pages/Venue/order/GuestReviewPage";
import ReviewSuccess from "src/pages/Venue/order/review/ReviewSuccess";
import ActiveOrderPage from "src/pages/Venue/order/ActiveOrderPage";
import Page404 from "src/pages/Page404";
import Profile from "src/pages/Venue/userProfile/Profile";
import VerifySuccess from "src/pages/auth/VerifySuccess";
import QrTableScan from "src/pages/qrcode/QrTableScan";
import QrVenueScan from "src/pages/qrcode/QrVenueScan";
import CarHopScan from "src/pages/qrcode/CarHopScan";
import PhoneNumberPage from "src/pages/auth/PhoneNumberPage";
import VenueProfilesGuard from "src/auth/VenueProfilesGuard";

export default function Router() {
  return useRoutes([
    {
      children: [
        {
          path: "/",
          element: (
            <AuthGuard>
              <SearchMainPage />
            </AuthGuard>
          ),
        },
        // {
        //   path: "/payment",
        //   element: (
        //     <AuthGuard>
        //       <PaymentCustomPage />
        //     </AuthGuard>
        //   ),
        // },
      ],
    },

    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <AuthGuard>
              <LoginPage />
            </AuthGuard>
          ),
        },
        {
          path: "register",
          element: (
            <AuthGuard>
              <RegisterPage />
            </AuthGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            { path: "reset-password", element: <AuthGuard><ResetPasswordPage /></AuthGuard>  },
            { path: "new-password", element: <AuthGuard><NewPasswordPage /></AuthGuard>  },
            { path: "verify", element: <AuthGuard><VerifyCodePage /> </AuthGuard> },
            { path: "verifysuccess", element:<AuthGuard> <VerifySuccess /> </AuthGuard>},
            { path: "email-check", element: <AuthGuard><CheckEmail /></AuthGuard> },
          ],
        },
      ],
    },
    {
      children: [
        {
          path: "venue",
          element: (
            <AuthGuard>
              <VenueMainPage />
            </AuthGuard>
          ),
        },
        {
          path: "venue/:venueId",
          element: (
             <AuthGuard>
              <VenueProfilesGuard>
                <VenueProfiles />
              </VenueProfilesGuard>
            </AuthGuard>
          ),
        },
      ],
    },
    // {
    //   children: [
    //     {
    //       path: "/phoneNumber",
    //       element: (
    //         <AuthGuard>
    //           <PhoneNumberPage />
    //         </AuthGuard>
    //       ),
    //     },
    //   ],
    // },
    {
      children: [
        {
          path: "active",
          element: (
            <AuthGuard>
              <ActiveOrderPage />
            </AuthGuard>
          ),
        },
      ],
    },
    {
      children: [
        {
          path: "checkout",
          element: (
            <AuthGuard>
              <CartCheckout />
            </AuthGuard>
          ),
        },
      ],
    },
    {
      children: [
        {
          path: "profile",
          element: (
            <AuthGuard>
              <Profile />
            </AuthGuard>
          ),
        },
      ],
    },

    {
      children: [
        {
          path: "order",
          element: (
            <AuthGuard>
              <OrdeHistoryPage />
            </AuthGuard>
          ),
        },
      ],
    },

    {
      children: [
        {
          path: "review",
          element: (
            <AuthGuard>
              <GuestReviewPage />
            </AuthGuard>
          ),
        },
      ],
    },

    {
      children: [
        {
          path: "reviewSuccess",
          element: <ReviewSuccess />,
        },
      ],
    },

    {
      children: [
        {
          path: "qrTable/:tableId",
          element: (
            <AuthGuard>
              <QrTableScan />
            </AuthGuard>
          ),
        },
      ],
    },

    {
      children: [
        {
          path: "qrVenue/:venueId",
          element: (
            <AuthGuard>
              <QrVenueScan />
            </AuthGuard>
          ),
        },
      ],
    },
    {
      children: [
        {
          path: "carHop/:venueId",
          element: (
            <AuthGuard>
              <CarHopScan />
            </AuthGuard>
          ),
        },
      ],
    },

    {
      path: "*",
      element: (
        <CompactLayout>
          <Page404 />
        </CompactLayout>
      ),
    },
  ]);
}
