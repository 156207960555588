import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import Iconify from "src/components/iconify/Iconify";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import QrScanner from "src/pages/qrcode/QrScanner";
import { calculateAndRoundTax } from "src/utils/tax";
import { useCart } from "../CartContext";
import CartModalAction from "./CartModalAction";
import CartModalContent from "./CartModalContent";

const CartItemModal = ({
  width,
  open,
  item,
  onClose,
  setOpenCarInformationModal,
  isUpsellingDialogOpen,
  setIsUpsellingDialogOpen,
  errorMessage,
}) => {
  const navigate = useNavigate();

  const { tableData } = useSelector((state) => state.tableData);
  const { isLoading } = useSelector((state) => state.cardSlice);
  const { venueIdToBlock } = useSelector((state) => state.venueLocationModal);

  let mode = localStorage.getItem("mode");

  const { calculateSubTotal, calculateServiceFee, isApplicable } = useCart();
  const { cardItems } = useSelector((state) => state.cardSlice);

  const taxRate = cardItems?.data?.venueDetail?.configurations?.isPayingTax
    ? cardItems?.data?.venueDetail?.taxOnCash / 100
    : 0;

  let discount = 0;

  let subTotal = calculateSubTotal(cardItems?.data?.items);
  const taxAmount = calculateAndRoundTax(subTotal, taxRate, discount);

  const [isScanning, setIsScanning] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState("");
  const [result, setResult] = useState("");

  const handleScan = (data) => {
    if (data) {
      setResult(data.text);
      setIsScanning(false);
      const lastPart = data.text.split("/").pop();
      const wholeText = data.text.split("/");
      if (wholeText[3] != "qrTable") {
        setError("Invalid QR Code");
      } else {
        onClose();
        const message = "ScannedLater";
        navigate(`/qrTable/${lastPart}`, { state: { message } });
        return;
      }
    }
  };

  const handleClose = () => {
    setIsScanning(false);
  };

  const handleError = (err) => {
    console.error(err);
  };

  let paymentOption = "cash"

  const { data } = cardItems ?? {};
  const { venueDetail } = data ?? {}
  const { serviceFeesObject, configurations } = venueDetail ?? {}
  const { isServiceFeesOnWeb } = configurations ?? {}

  const serviceFee = useMemo(
    () =>
      cardItems?.data?.items?.length > 0 &&
        (isServiceFeesOnWeb && (isApplicable(serviceFeesObject?.[mode]?.cash?.applicable)))
        ? calculateServiceFee(cardItems, mode, paymentOption, subTotal, discount)
        : 0,
    [cardItems, subTotal, taxAmount]
  );

  const renderImage = () => {
    if (mode === "dineIn" || mode === "QrDineIn") {
      return (
        <img
          src="/assets/header-tabs/dine-in-orange1.svg"
          alt="Dine In"
          style={{ marginRight: "8px", width: "30px", height: "30px" }}
        />
      );
    } else if (mode === "pickUp" || mode === "QrPickUp" || mode === 'carHop') {
      return (
        <img
          src="/assets/header-tabs/Pickup-orange.svg"
          alt="Pick Up"
          style={{ marginRight: "8px", width: "30px", height: "30px" }}
        />
      );
    }
    return null;
  };
  return (
    <>
      <ConfirmDialog width={width} open={open} onClose={onClose}>
        {isLoading ? (
          <LoadingScreen width="100px" height="100px" marginT="0" h="50vh" />
        ) : isScanning ? (
          <>
            <QrScanner
              onScan={handleScan}
              onError={handleError}
              onClose={handleClose}
            />
          </>
        ) : (
          <>
            <DialogTitle
              sx={{
                "@media (max-width: 600px)": {
                  padding: "16px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "25px",
                    m: 0,
                    p: 0,
                  }}
                >
                  Cart
                </Typography>

                <Box display="flex" alignItems="center" flexDirection="column">
                  <Box display="flex" alignItems="center">
                    {renderImage()}{" "}
                    <Typography
                      sx={{
                        color: "#F08203",
                        fontWeight: "600",
                        fontSize: "18px",
                        m: 0,
                        p: 0,
                      }}
                    >
                      {mode === "dineIn"
                        ? "Dine In"
                        : mode === "pickUp"
                          ? "Pick Up"
                          : mode === "QrDineIn" ? "QR Dine In" : mode === "QrPickUp" ? "QR Pick Up" : mode === "carHop" ? "Carhop QR" : ""}
                    </Typography>
                  </Box>
                  {(mode === "dineIn" || mode === "QrDineIn") &&
                    tableData &&
                    tableData.isAvailable && (
                      <Box>
                        <Button
                          sx={{
                            height: "30px",
                            borderRadius: "20px",
                            background: "#000000",
                            color: "#FFFFFF",
                            marginTop: "2px",
                            "@media (max-width: 768px)": {
                              height: "25px",
                              fontSize: "10px",
                            },
                          }}
                        >
                          Table: {tableData?.isAvailable ? `(${tableData?.name})` : ""}
                        </Button>
                      </Box>
                    )}
                </Box>

                <Box sx={{ m: 0, p: 0 }}>
                  <IconButton
                    aria-label="close"
                    onClick={() => onClose(!open)}
                    sx={{
                      position: {
                        xs: "static",
                      },
                      top: 20,
                      right: 8,
                    }}
                  >
                    <Iconify
                      sx={{
                        height: 25,
                        width: 25,
                      }}
                      icon="mdi:close"
                      color="#fca92e"
                    />
                  </IconButton>
                </Box>
              </Box>
            </DialogTitle>
            <Box>
              <Divider />
            </Box>
            <DialogContent
              sx={{
                typography: "body2",
                "@media (max-width: 600px)": {
                  padding: "16px",
                },
              }}
            >
              <CartModalContent taxAmount={taxAmount} serviceFee={serviceFee} />
            </DialogContent>
            <Box>
              <Divider />
            </Box>
            {(errorMessage || error) && (
              <Typography
                style={{
                  textAlign: "center",
                  color: "red",
                  margin: "20px 0",
                }}
              >
                {errorMessage ? errorMessage : error}
              </Typography>
            )}
            <DialogActions
              sx={{
                display: "block",
                "@media (max-width: 600px)": {
                  padding: "16px !important",
                },
              }}
            >
              <CartModalAction
                venueIdToBlock={venueIdToBlock}
                open={open}
                onClose={onClose}
                taxAmount={taxAmount}
                serviceFee={serviceFee}
                setIsDisabled={setIsDisabled}
                isDisabled={isDisabled}
                setIsScanning={setIsScanning}
                setOpenCarInformationModal={setOpenCarInformationModal}
                isUpsellingDialogOpen={isUpsellingDialogOpen}
                setIsUpsellingDialogOpen={setIsUpsellingDialogOpen}
              />
            </DialogActions>
          </>
        )}
      </ConfirmDialog>
    </>
  );
};
export default CartItemModal;
