import React, { useState, useEffect } from "react";
import {
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Divider,
  Switch,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import ConfirmDialog from "src/components/confirm-dialog";
import Image from "src/components/image";
import Iconify from "src/components/iconify";
import { StarIcon } from "src/theme/overrides/CustomIcons";
import { fetchImage } from "src/utils/fetchImage";
import { fNumber } from "src/utils/formatNumber";
import { useSelector, useDispatch } from "react-redux";
import { getOrderHistoryByCustomerId } from "src/redux/slices/orderPlaceSlice";
import capitalizeWords from "src/utils/capitalizeWords";

import { useSnackbar } from "src/components/snackbar";

const ReviewModal = ({ width, detail, open, onClose }) => {
  const [ratings, setRatings] = useState({
    ambiance: 0,
    waitTime: 0,
    foodQuality: 0,
    service: 0,
    overAllExperience: 0,
    valueForMoney: 0,
  });

  const { user } = useSelector((state) => state.login);
  const [imageURLs, setImageURLs] = useState([]);
  const [venueImageURL, setVenueImageURL] = useState("");
  const [itemWiseReview, setItemWiseReview] = useState(false);
  const [itemRatings, setItemRatings] = useState({});
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchVenueImage = async () => {
      if (detail?.venueId?.photoURL) {
        try {
          const res = await fetchImage(detail?.venueId?.photoURL);
          setVenueImageURL(res);
        } catch (error) {
          console.error("Error fetching venue image:", error);
        }
      }
    };

    fetchVenueImage();
  }, [detail?.venueId?.photoURL]);

  useEffect(() => {
    const fetchItemImages = async () => {
      if (detail?.items) {
        try {
          const imagePromises = detail.items.map((item) =>
            fetchImage(item?.photoURL)
          );
          const fetchedImages = await Promise.all(imagePromises);
          setImageURLs(fetchedImages);
        } catch (error) {
          console.error("Error fetching item images:", error);
        }
      }
    };

    if (open) {
      fetchItemImages();
    }
  }, [detail?.items, open]);

  const handleSubmit = async () => {
    setIsLoading(true);
    const orderId = detail?.id;
    const venueId = detail?.venueId?.id;
    const levelId = detail?.levelId;

    const requestBody = {
      overallOrderRating: ratings,
      orderId,
      venueId,
      levelId,
    };

    if (itemWiseReview) {
      const itemsRating = Object.keys(itemRatings).map((itemId) => ({
        id: itemId,
        itemRating: itemRatings[itemId],
      }));
      requestBody.itemsRating = itemsRating;
    }

    if (comment.trim() !== "") {
      requestBody.comments = comment;
    }

    if (itemWiseReview) {
      const allItemsRated = detail.items.every(
        (item) => itemRatings[item.id] > 0
      );
      if (!allItemsRated) {
        enqueueSnackbar("Please rate all items before submitting.", {
          variant: "warning",
        });
        setIsLoading(false);
        return;
      }
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/review`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        console.log("Review submitted successfully:", response.data);
        enqueueSnackbar("Review Submitted successfully!");
        onClose();
        dispatch(getOrderHistoryByCustomerId(user?.id));
      } else {
        console.error("Failed to submit review:", response.data);
        enqueueSnackbar("Failed to submit review. Please try again later.");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        enqueueSnackbar("Review for this order already submitted.");
      } else {
        console.error("Error submitting review:", error);
        enqueueSnackbar("Failed to submit review. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const questionsToRate = [
    detail?.ordertype === "QrDineIn" || detail?.ordertype === "dineIn"
      ? "ambiance"
      : "waitTime",
    "foodQuality",
    "service",
    "overAllExperience",
    "valueForMoney",
  ];

  const allQuestionsRated = questionsToRate.every(
    (question) => ratings[question] > 0
  );

  const ratingMessages = {
    ambiance: " Ambiance",
    waitTime: "Wait time",
    foodQuality: "Food quality",
    service: "Service",
    overAllExperience: "Overall experience",
    valueForMoney: "Value for money",
  };

  const handleRatingChange = (question, rating) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [question]: prevRatings[question] === rating ? 0 : rating,
    }));
  };

  const handleItemRatingChange = (itemId, rating) => {
    setItemRatings((prevItemRatings) => ({
      ...prevItemRatings,
      [itemId]: prevItemRatings[itemId] === rating ? 0 : rating,
    }));
  };

  const renderStars = (question, isItem = false, itemId = null) => {
    return [...Array(5)].map((_, index) => {
      const isSelected = isItem
        ? itemRatings[itemId] > index
        : ratings[question] > index;

      return (
        <StarIcon
          key={index}
          onClick={() => {
            if (isItem) {
              handleItemRatingChange(itemId, index + 1);
            } else {
              handleRatingChange(question, index + 1);
            }
          }}
          sx={{
            cursor: "pointer",
            color: isSelected ? "#fca92e" : "#ccc",
            fontSize: {
              xs: "18px",
              sm: "30px",
            },
            margin: "0 2px",
          }}
        />
      );
    });
  };

  return (
    <ConfirmDialog open={open} width={width} onClose={onClose}>
      <DialogTitle sx={{ paddingBottom: "0" }}>
        {detail?.orderReviewed == true ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}></Box>
            <IconButton aria-label="close" onClick={onClose}>
              <Iconify
                sx={{
                  height: 25,
                  width: 25,
                }}
                icon="mdi:close"
                color="#fca92e"
              />
            </IconButton>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Image
                src={venueImageURL ? venueImageURL : "/assets/placeholder.png"}
                alt={"image"}
                style={{
                  height: "100px",
                  width: "140px",
                  borderRadius: "5px",
                }}
              />
              <Box variant="h2">
                {detail?.venueId?.name}
                <Typography sx={{ fontWeight: 600 }}>
                  Order # {detail?.billNumber}
                </Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  Total: Rs. {fNumber(detail?.total)}
                </Typography>
              </Box>
            </Box>
            <IconButton aria-label="close" onClick={onClose}>
              <Iconify
                sx={{
                  height: 25,
                  width: 25,
                }}
                icon="mdi:close"
                color="#fca92e"
              />
            </IconButton>
          </Box>
        )}
      </DialogTitle>
      <Box mt={1}>
        <Divider />
      </Box>
      <DialogContent sx={{ marginTop: "20px" }}>
        {detail?.orderReviewed == true ? (
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: {
                xs: "14px",
                sm: "16px",
                md: "16px",
              },
            }}
          >
            You have already submitted the review for this order.
          </Typography>
        ) : (
          <>
            {questionsToRate?.map((question) => (
              <Box
                key={question}
                mb={2}
                mt={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "16px",
                      md: "16px",
                    },
                  }}
                >
                  {ratingMessages[question]}
                </Typography>
                <Box>{renderStars(question)}</Box>
              </Box>
            ))}

            <Divider />

            <Box
              mb={2}
              mt={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "16px",
                    md: "16px",
                  },
                }}
              >
                Want to review Item
              </Typography>
              <Switch
                checked={itemWiseReview}
                onChange={() => setItemWiseReview(!itemWiseReview)}
                color="primary"
              />
            </Box>

            {itemWiseReview && (
              <Box>
                {detail?.items?.map((item, index) => {
                  const isLastItem = index === detail.items.length - 1;
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",

                        "@media (max-width: 600px)": {
                          flexDirection: "column",
                          alignItems: "flex-start",
                        },
                        alignItems: "center",
                        gap: "10px",
                        mb: 1,
                      }}
                      mb={1}
                      mt={1}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Image
                          src={
                            imageURLs[index]
                              ? imageURLs[index]
                              : "/assets/placeholder.png"
                          }
                          alt={"image"}
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "5px",
                          }}
                        />
                        <Box>
                          <Box sx={{ fontSize: "14px" }}>
                            {item?.qty} X {capitalizeWords(item?.name)}
                          </Box>

                          {item?.groups && item?.groups?.length > 0 && (
                            <>
                              {item?.selectedVariant && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 12,
                                      fontWeight: "bold",
                                      color: "#666",
                                    }}
                                  >
                                    Variant:
                                  </Typography>
                                  <Typography sx={{ ml: 1, fontSize: 12 }}>
                                    {item?.selectedVariant?.name}
                                  </Typography>
                                </Box>
                              )}
                              <>
                                {item?.groups?.map((sauce) => (
                                  <Typography
                                    key={index}
                                    variant="caption"
                                    sx={{
                                      color: "#666",
                                      display: "flex",
                                      gap: "2px",
                                      fontWeight: "bold",
                                      "@media (max-width: 600px)": {
                                        fontSize: "10px",
                                      },
                                    }}
                                  >
                                    {sauce?.name} :
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        color: "#666",
                                        display: "flex",
                                        marginLeft: "5px",
                                        gap: "2px",
                                        "@media (max-width: 600px)": {
                                          fontSize: "10px",
                                        },
                                      }}
                                    >
                                      {sauce?.items?.map((item, index) => (
                                        <span key={index}>
                                          {item?.item}
                                          {index !== sauce.items.length - 1 &&
                                            ", "}
                                        </span>
                                      ))}
                                    </Typography>
                                  </Typography>
                                ))}
                              </>
                            </>
                          )}
                          <Box sx={{ fontSize: "14px", color: "red" }}>
                            {item?.notes}
                          </Box>
                          <Box
                            sx={{
                              display: { xs: "block", sm: "none" },
                              mt: 1,
                              ml: -0.5,
                            }}
                          >
                            <Box>{renderStars(item.id, true, item.id)}</Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box sx={{ display: { xs: "none", sm: "block" } }}>
                        <Box>{renderStars(item.id, true, item.id)}</Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}

            <TextField
              label="Add a comment"
              variant="outlined"
              fullWidth
              multiline
              rows={2}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              sx={{ mb: 2, mt: 4 }}
            />
          </>
        )}
      </DialogContent>

      <DialogActions>
        {!detail?.orderReviewed && (
          <LoadingButton
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            loading={isLoading}
            disabled={!allQuestionsRated}
            sx={{
              width: "100%",
              height: "40px",
              fontSize: "16px",
            }}
          >
            Submit
          </LoadingButton>
        )}
      </DialogActions>
    </ConfirmDialog>
  );
};

export default ReviewModal;
