import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import VerifyEmailProcess from "../../../assets/icons/emailVerifiyProcess.svg";
import AuthVerifyCodeForm from "../../../sections/auth/AuthVerifyCodeForm";
import verifyImg from "../../../assets/icons/Verify.svg";
import CodeVerified from "../../../assets/icons/codeVerified.svg";

import Iconify from "src/components/iconify/Iconify";


const VerificationDialog = ({ open, onClose,phoneNumberForModal }) => {

    const location = useLocation();
    const verificationPathForProfile = location.state?.profilePath
    const queryParams = new URLSearchParams(location.search);
    const verificationType = queryParams.get('verify');
    const [showVerified, setShowVerified] = useState(false);
    const [isCodeFormFilled, setIsCodeFormFilled] = useState(false);
    const [verificationPath, setVerificationPath] = useState(verificationType);
    const [profilePath, setRedirectPath] = useState(verificationPathForProfile);
  
    const handleChangeVerificationPath = () => {
      setVerificationPath("Email");
    };
  
    const updateIsCodeFormFilled = (filled) => {
      setIsCodeFormFilled(filled);
    };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{ style: { maxHeight: "90vh" } }}
    >
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box display="flex" justifyContent="center">
          <Typography variant="h6">Verification</Typography>
        </Box>
        <IconButton aria-label="close" onClick={onClose}>
          <Iconify
            sx={{
              height: 25,
              width: 25,
            }}
            icon="mdi:close"
            color="#fca92e"
          />
        </IconButton>
      </DialogActions>
      <DialogContent sx={{mb:"20px"}}>
      <Box display="flex" justifyContent="center" >
            <img
              src={verificationPath == 'Email'? VerifyEmailProcess : verificationPath == 'Phone'? verifyImg : CodeVerified }
              alt="verification"
              style={{ width: "200px", height: "200px" }}
            />
          </Box>
         
          <Typography sx={{ color: "text.secondary", mb: 2, mt:4 }}>
            Enter the four digit code sent to your 
            {verificationPath === "Email" ? " email address" : " mobile number"}
          </Typography>
          <AuthVerifyCodeForm
            handleChangeVerificationPath={handleChangeVerificationPath}
            verificationPath={verificationPath}
            profilePath={profilePath}
            handleVerified={setShowVerified}
            updateIsCodeFormFilled={updateIsCodeFormFilled}
            isModal={true}  
            onCloseModal={onClose}  
            phone={true}
            phoneNumberForModal={phoneNumberForModal}
          />
   
       
      
      </DialogContent>
    </Dialog>
  );
};

export default VerificationDialog;
