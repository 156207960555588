import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import VenueHeader from "../Venue/VenueHeader";
import FooterLogin from "src/layouts/compact/FooterLogin";
import { Box } from "@mui/material";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchTableData, clearTableData } from "src/redux/slices/tableSlice";
import useSession from "src/utils/useSession";
import { selectedVenue } from "src/utils/venueSelectionUtils";
import {
  deleteCartBySessionId,
  getCartByCustomerId,
} from "src/redux/slices/cardSlice";
import axios from "axios";
import { useLocation } from 'react-router-dom';

const QrTableScan = () => {
  const { tableId } = useParams();
  const navigate = useNavigate();
  const sessionInfo = useSession();

  const dispatch = useDispatch();
  const { cardItems } = useSelector((state) => state?.cardSlice);
  const [message, setMessage] = useState("");
  const location = useLocation();
  const laterScanned = location.state?.message;
  
  const RemoveItemFromCart = async (data) => {
    try {
      if (data?.items?.length > 0) {
        let res = await dispatch(deleteCartBySessionId(sessionInfo?.sessionId));
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      if (location.state && location.state.message) {
        delete location.state.message;
      }
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/tables/${tableId}`);
      if(res.data.isDeleted == true){
        setMessage("Sorry, this QR code is not working. Please contact the staff.");
      }else{
        const response = await axios.get( `${process.env.REACT_APP_BACKEND_URL}/venues/b2c/${res?.data?.venueId}`);
        if(response?.data?.status == 'inActive'){
          setMessage("Sorry, this venue is not available at the moment.Please try again later.");
        }else if(response.data.configurations.isQrTableScanAvailable == false || response?.data?.configurations?.isDineIn == false){
          setMessage("Sorry, this QR code is not working. Please contact the staff.");
        }else{
          const response = await dispatch(getCartByCustomerId(sessionInfo?.sessionId));
          if (res?.data?.venueId != response?.data?.venueId && laterScanned) {
            const errorMessage = 'Please Scan the table of Same Venue you are adding the Items from.'
            navigate(`/venue/${response.data.venueId}`, { replace: true, state: { errorMessage } });
            return
          } else {
            await dispatch(fetchTableData(tableId));
            let openModal;
            if(laterScanned){
              openModal = true
            }else{
              openModal = false
            }
            if (res?.status === 200) {
              const timeDifference = res.data.timePartiallyBooked? (new Date() - new Date(res.data.timePartiallyBooked))/(1000 * 60) : 0
              if (!res.data.isAvailable || (res.data.timePartiallyBooked && sessionInfo?.sessionId != res.data.sessionId && (timeDifference < 5) )) {
                dispatch(clearTableData());
                setMessage("This table is not Available");
                if(laterScanned){
                  const errorMessage = 'This table is not Available'
                  navigate(`/venue/${response.data.venueId}`, { replace: true, state: { errorMessage, openModal } });
                  return
                }
              } else if (res?.data?.isAvailable === true) {
                if (response?.data?.items?.length > 0 && response?.data?.venueId != res?.data?.venueId) {
                  let res1 = await RemoveItemFromCart(response?.data);
                  if (res1?.status === 204) {
                    localStorage.setItem("mode", "QrDineIn");
                    localStorage.setItem("venueId", res?.data?.venueId);
                    selectedVenue(dispatch, "QrDineIn", true);
                    const changeMode = "QrDineIn"
                    navigate(`/venue/${res?.data?.venueId}`, { replace: true, state: { changeMode,openModal } });
                    return
                  }
                } else {
                  localStorage.setItem("mode", "QrDineIn");
                  selectedVenue(dispatch, "QrDineIn", true);
                  localStorage.setItem("venueId", res?.data?.venueId);
                  const changeMode = "QrDineIn"
                  navigate(`/venue/${res?.data?.venueId}`, { replace: true, state: { changeMode, openModal } });
                  return
                }
              } 
            }
    
          }
        }
        
      }
    } catch (error) {
      if(error?.response?.data?.message == 'Table not found' || error?.response?.data?.message == "\"\"tableId\"\" must be a valid mongo id"){
        navigate(`/`);
        return
      }
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [location]);

  useEffect(() => {
    setMessage("")
    fetchData();
  }, [dispatch]);

  return (
    <div>
      <Helmet>
        <title>Egora-QR Code</title>
      </Helmet>
      <VenueHeader />
      <Box
        mt={20}
        sx={{
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: "40px",
        }}
      >
        {message}
      </Box>
      <FooterLogin />
    </div>
  );
};

export default QrTableScan;
