import React, { useCallback, useEffect } from "react";
import { useCart } from "../CartContext";
import { useSwipeable } from "react-swipeable";

import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  IconButton,
  InputAdornment,
  Card,
} from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  removeItemFromCart,
  updateItemsToCard,
  setPickUpAndDineIn,
} from "src/redux/slices/cardSlice";
import { fetchImage } from "src/utils/fetchImage";
import Image from "src/components/image/Image";
import useSession from "src/utils/useSession";
import { CustomTextFieldForCart } from "src/components/custom-input/CustomTextField";
import VenueItmeModalEdit from "../Venue Profiles/venueModalEdit/VenueItmeModalEdit";
import { useNavigate } from "react-router";
import { useMediaQuery } from "@mui/material";
import { color } from "@mui/system";
import { useLocation } from "react-router-dom";
import { fNumber } from "src/utils/formatNumber";
import CartItems from "./CartItems";

const CartModalContent = ({ taxAmount, serviceFee, open, onClose }) => {
  const sessionInfo = useSession();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { cardItems } = useSelector((state) => state.cardSlice);
  const {
    selectedTip,
    handleTipChange,
    calculateSubTotal,
    calculateMenuItemTotal,
    isApplicable
  } = useCart();
  const [openConfirm1, setOpenConfirm1] = useState(false);
  const [orderItem, setOrderItem] = useState(null);
  const [isTipVisible, setIsTipVisible] = useState(false);
  let mode = localStorage.getItem("mode");
  const [showDeleteIndex, setShowDeleteIndex] = useState(null);

  const handleMenuItemClick = (item) => {
    setOpenConfirm1(true);
    setOrderItem(item);
  };

  const closeModal = () => {
    setOpenConfirm1(false);
  };

  const handleRemoveFromCart = async (item) => {
    await dispatch(
      removeItemFromCart(
        cardItems?.data?.id,
        item?.cartItemId,
        sessionInfo?.sessionId,
        item?.venueId,
        item?.levelId
      )
    );
    if (cardItems?.data?.items.length === 1) {
      dispatch(
        setPickUpAndDineIn({
          isPickUp: false,
          isDineIn: false,
        })
      );
      handleTipChange(null);
      navigate(`/venue/${cardItems?.data?.venueId}`);
      return;
    }
  };

  const [imageURLs, setImageURLs] = useState([]);

  useEffect(() => {
    const fetchImagesForCart = async () => {
      if (cardItems?.data?.items && cardItems?.data?.items.length > 0) {
        const urls = await Promise.all(
          cardItems?.data?.items.map(async (elem) => {
            try {
              const res = await fetchImage(elem.photoURL);
              return res;
            } catch (error) {
              console.error("Error fetching data:", error);
              return null;
            }
          })
        );
        setImageURLs(urls);
      }
    };

    fetchImagesForCart();
  }, [cardItems]);

  const updateItemFromCardAddByOne = useCallback(async (item) => {
    try {
      const updatedItem = {
        ...item,
        qty: item.qty + 1,
      };
      await dispatch(updateItemsToCard(updatedItem, sessionInfo?.sessionId));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  const updateItemFromCardDecByOne = useCallback(async (item) => {
    try {
      const updatedItem = {
        ...item,
        qty: item.qty - 1,
      };
      await dispatch(updateItemsToCard(updatedItem, sessionInfo?.sessionId));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  const tipOptions = [
    { label: "Rs. 0", value: 0 },
    { label: "Rs. 50", value: 50 },
    { label: "Rs. 100", value: 100 },
  ];

  const venuePath = `/venue/${cardItems?.data?.venueId}`;

  const isMobile = useMediaQuery("(max-width:600px)");

  const handleMenuPage = () => {
    navigate(`/venue/${cardItems?.data?.venueId}`);
  };

  const { data } = cardItems ?? {};
  const { venueDetail } = data ?? {}
  const { serviceFeesObject, configurations } = venueDetail ?? {}
  const { isServiceFeesOnWeb } = configurations ?? {}

  const renderServiceFee = () => {
    if (
      isServiceFeesOnWeb &&
      isApplicable(serviceFeesObject?.[mode]?.cash?.applicable) && serviceFee > 0) {
      return (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "14px",
            marginTop: "-12px",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "500",
              fontSize: "15px",
            }}
          >
            Service Fee
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "15px",
            }}
          >
            Rs. {fNumber(serviceFee)}
          </Typography>
        </Stack>
      );
    }
    return null;
  };

  return (
    <Box>

      {cardItems?.data?.items?.map((cartItem, index) => (
        <CartItems
          key={index}
          cartItem={cartItem}
          index={index}
          showDeleteIndex={showDeleteIndex}
          setShowDeleteIndex={setShowDeleteIndex}
          handleRemoveFromCart={handleRemoveFromCart}
          handleMenuItemClick={handleMenuItemClick}
        />
      ))}

      {cardItems?.data?.items?.length > 0 && (
        <>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              margin: "14px",
              fontWeight: "600",
              fontSize: "15px",
              cursor: "pointer",
              color: "#F08203",
            }}
            onClick={handleMenuPage}
            style={{ display: currentPath !== venuePath ? "flex" : "none" }}
          >
            + Add more Items
          </Typography>

          <Card
            sx={{
              marginBottom: "20px",
              border: "0.5px solid #C0B9B9",
            }}
          >
            <Box>
              <Typography
                sx={{
                  margin: "14px",
                  fontWeight: "700",
                  fontSize: "15px",
                }}
              >
                Order Summary
              </Typography>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "14px",
                  marginTop: "20px",
                }}
              >
                <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                  Sub Total
                </Typography>
                <Typography sx={{ fontWeight: "600", fontSize: "15px" }}>
                  Rs. {fNumber(calculateSubTotal(cardItems?.data?.items))}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "14px",
                  marginTop: "-12px",
                }}
              >
                <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                  Platform Fee
                </Typography>
                <Typography sx={{ fontWeight: "600", fontSize: "15px" }}>
                  Rs. 9.9
                </Typography>
              </Stack>
              {renderServiceFee()}

              {/* {cardItems?.data?.venueDetail?.configurations?.isServiceFeesOnWeb &&
                (cardItems?.data?.venueDetail?.serviceFeesObject
                  ?.paymentOption === "Cash" ||
                  cardItems?.data?.venueDetail?.serviceFeesObject
                    ?.paymentOption === "Both") && (
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      margin: "14px",
                      marginTop: "-12px",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      Service Fee
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      Rs. {fNumber(serviceFee)}
                    </Typography>
                  </Stack>
                )} */}

              {cardItems?.data?.venueDetail?.configurations?.isPayingTax && (
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "14px",
                    marginTop: "-12px",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    Tax
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    Rs. {taxAmount ? fNumber(taxAmount) : 0}
                  </Typography>
                </Stack>
              )}
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  margin: "14px",
                  alignItems: "center",
                  "@media (max-width: 600px)": {
                    alignItems: "start",
                  },
                }}
              >
                <Button
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "#F08203",
                  }}
                  onClick={() => setIsTipVisible((prev) => !prev)}
                >
                  {isTipVisible ? "- Add Tip" : "+ Add Tip"}
                </Button>
                {isTipVisible && (<Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    gap: "20px",
                    "@media (max-width: 600px)": {
                      gap: "10px",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    },
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {tipOptions.map((tipOption) => (
                      <Button
                        key={tipOption.value}
                        variant={
                          selectedTip === tipOption.value
                            ? "contained"
                            : "outlined"
                        }
                        sx={{
                          p: 0,
                          height: "36px",
                          fontSize: { xs: "12px", sm: "14px" },
                        }}
                        onClick={() => handleTipChange(tipOption.value)}
                      >
                        {tipOption.label}
                      </Button>
                    ))}
                  </Stack>

                  <CustomTextFieldForCart
                    type="number"
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="custom tip"
                    value={selectedTip}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value >= 0 && value.length <= 6) {
                        handleTipChange(value);
                      }
                    }}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Rs.</InputAdornment>
                      ),
                      inputProps: {
                        maxLength: 6,
                      },
                    }}
                  />
                </Box>)}
              </Stack>
            </Box>
          </Card>
        </>
      )}
      {openConfirm1 && (
        <VenueItmeModalEdit
          width="md"
          open={openConfirm1}
          setOpenConfirm1={setOpenConfirm1}
          onClose={closeModal}
          item={orderItem}
          data={cardItems?.data}
        />
      )}
    </Box>
  );
};

export default CartModalContent;
