import { useEffect } from "react";
import { Box, Divider, IconButton } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import VenueModalHeader from "./VenueModalHeader";
import VenueModalContent from "./VenueModalContent";
import VenueModalAction from "./VenueModalAction";
import { useSnackbar } from "../../../../components/snackbar";
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addItemsToCard } from "src/redux/slices/cardSlice";
import useSession from "src/utils/useSession";
import VenueSkipModalContent from "./VenueSkipModalContent";
import VenueSkipModalAction from "./VenueSkipModalAction";
import { deleteCartBySessionId } from "src/redux/slices/cardSlice";
import { setPickUpAndDineIn } from "src/redux/slices/cardSlice";
import { clearTableData } from "src/redux/slices/tableSlice";
import previousOrderType from "src/utils/previousOrderType";
import { fNumber } from "src/utils/formatNumber";
import { resetVenueIdtoBlock } from "src/redux/slices/VenueLocationModalSlice";

const VenueItmeModal = ({
  width,
  open,
  item,
  venuesCoverData,
  modeOfVenue,
  venueId,
  onClose,
  venueIdToBlock,
}) => {
  const [openSkipModal, setOpenSkipModal] = useState(false);
  const { tableData } = useSelector((state) => state.tableData);
  const sessionInfo = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [notes, setNotes] = useState("");
  const dispatch = useDispatch();
  const { choiceByGroup } = useSelector((state) => state.choiceByGroup);
  const { cardItems } = useSelector((state) => state.cardSlice);
  const [isLoadings, setIsLoading] = useState(false);

  const [selectedSauces, setSelectedSauces] = useState({
    items: [],
  });

  function generateRandomHexString(length) {
    let result = "";
    const characters = "0123456789abcdef";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [quantity, setQuantity] = useState(1);

  const [selectedVariant, setSelectedVariant] = useState([]);

  useEffect(() => {
    const defaultVariant = choiceByGroup?.variants?.find(
      (item) => item.defaultVariant === true
    );
    setSelectedVariant(defaultVariant);
  }, [choiceByGroup.variants]);

  const toggleVariantSelect = (variant) => {
    setSelectedVariant(variant);
    setSelectedSauces({ items: [] });
  };

  const calculateTotalPrice = () => {
    const basePrice = selectedVariant
      ? parseFloat(selectedVariant.price)
      : parseFloat(item?.price);
    const addOnPrices = (item?.addOns || []).reduce((total, addon) => {
      const addonPrice = parseFloat(addon.price.replace("Rs. ", ""));
      return total + addonPrice;
    }, 0);

    let saucePrices = 0;
    if (selectedSauces?.items?.length > 0) {
      saucePrices = selectedSauces.items
        .map((sauce) => sauce.items)
        .flat()
        .reduce((total, item) => total + parseFloat(item.price), 0);
    }
    const calTotalPrice = (basePrice + addOnPrices + saucePrices) * quantity;
    return `Rs. ${fNumber(calTotalPrice)}`;
  };

  const handleIncreaseQuantity = () => {
    if (quantity < 1000) {
      setQuantity(quantity + 1);
    }
  };  

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const toggleSauce = (elem, sauce) => {
    let updatedItems = [...selectedSauces.items];

    const itemIndex = updatedItems.findIndex((item) => item.id === elem.id);
    if (itemIndex !== -1) {
      const isSauceSelected = updatedItems[itemIndex].items.includes(sauce);
      if (isSauceSelected) {
        updatedItems[itemIndex].items = updatedItems[itemIndex].items.filter(
          (selected) => selected !== sauce
        );
      } else {
        if (updatedItems[itemIndex].items.length < elem?.quantity) {
          updatedItems[itemIndex].items.push(sauce);
        } else {
          updatedItems[itemIndex].items.shift();
          updatedItems[itemIndex].items.push(sauce);
        }
      }
      if (updatedItems[itemIndex].items.length === 0) {
        updatedItems.splice(itemIndex, 1);
      }
    } else {
      updatedItems.push({ ...elem, items: [sauce] });
    }
    const isEmpty = updatedItems.length === 0;
    setSelectedSauces(isEmpty ? { items: [] } : { items: updatedItems });
  };
  const handleCartAction = useCallback(
    async (clearCart = false, item) => {
      try {
        setIsLoading(true);
        const orderType = localStorage.getItem("mode");
        if (
          orderType == "pickUp" ||
          orderType == "QrPickUp" ||
          orderType == "carHop"
        ) {
          dispatch(clearTableData());
        }

        if (cardItems?.data?.items?.length > 0) {
          if (tableData?.venueId && venueId !== tableData.venueId) {
            setOpenSkipModal(true);
            if (!clearCart) return;
          }

          if (!clearCart && item?.levelId !== cardItems?.data?.levelId) {
            setOpenSkipModal(true);
            return;
          }
        }
        if (clearCart) {
          if (tableData && tableData.venueId && venueId !== tableData.venueId) {
            dispatch(clearTableData());
          }
          await dispatch(deleteCartBySessionId(sessionInfo?.sessionId));
        }

        const prevType = await previousOrderType(venueId);
        if (prevType == "Set DineIn") {
          dispatch(resetVenueIdtoBlock());
          localStorage.setItem("mode", "dineIn");
          localStorage.removeItem("venueId");
          dispatch(clearTableData());
        } else if (prevType == "Set PickUp") {
          dispatch(resetVenueIdtoBlock());
          localStorage.setItem("mode", "pickUp");
          localStorage.removeItem("venueId");
        }

        const saucePrice = selectedSauces.items.reduce(
          (sum, elem) =>
            sum +
            (elem.items
              ? elem.items.reduce(
                  (innerSum, item) => innerSum + Number(item.price),
                  0
                )
              : 0),
          0
        );
        const totalPrice = Math.round(saucePrice).toFixed(0);
        const cartItem = {
          ...item,
          selectedVariant,
          isPrepared: false,
          price: selectedVariant?.price || item?.price,
          priceWithChoiceGroup:
            Number(selectedVariant?.price || item?.price) + Number(totalPrice),
          cartItemId: generateRandomHexString(24),
          qty: quantity,
          notes,
          groups: selectedSauces.items,
        };
        const res = await dispatch(
          addItemsToCard(cartItem, sessionInfo?.sessionId)
        );
        dispatch(
          setPickUpAndDineIn({
            isPickUp: modeOfVenue?.isPickUp,
            isDineIn: modeOfVenue?.isDineIn,
          })
        );
        if (res?.status === 201) {
          setQuantity(1);
          onClose();
        }
      } catch (error) {
        setIsLoading(false);
        enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
      } finally {
        setIsLoading(false);
        setSelectedSauces({ items: [] });
      }
    },
    [selectedSauces, quantity, notes, selectedVariant, tableData, cardItems]
  );

  return (
    <>
      <ConfirmDialog width={width} open={open} onClose={onClose}>
        <DialogContent sx={{ typography: "body2" }}>
          {!openSkipModal && (
            <DialogTitle
              sx={{
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: !openSkipModal ? "space-between" : "flex-end",
                }}
              >
                {!openSkipModal && (
                  <VenueModalHeader
                    item={item}
                    selectedVariant={selectedVariant}
                  />
                )}
                <IconButton aria-label="close" onClick={onClose}>
                  <Iconify
                    sx={{
                      height: 25,
                      width: 25,
                    }}
                    icon="mdi:close"
                    color="#fca92e"
                  />
                </IconButton>
              </Box>
            </DialogTitle>
          )}

          <Box>
            <Divider />
          </Box>
          {!openSkipModal ? (
            <VenueModalContent
              item={item}
              selectedSauces={selectedSauces}
              toggleSauce={toggleSauce}
              notes={notes}
              setNotes={setNotes}
              setSelectedSauces={setSelectedSauces}
              selectedVariant={selectedVariant}
              toggleVariantSelect={toggleVariantSelect}
            />
          ) : (
            <VenueSkipModalContent venueId={venueId} onClose={onClose} isLoading={isLoadings} />
          )}

          <Divider sx={{ marginTop: "20px" }} />
        </DialogContent>

        <DialogActions sx={{ display: "block" }}>
          {!openSkipModal ? (
            <VenueModalAction
              venueIdToBlock={venueIdToBlock}
              venueId={venueId}
              quantity={quantity}
              setQuantity={setQuantity}
              handleIncreaseQuantity={handleIncreaseQuantity}
              handleDecreaseQuantity={handleDecreaseQuantity}
              selectedSauces={selectedSauces}
              calculateTotalPrice={calculateTotalPrice}
              addItemToCart={() => handleCartAction(false, item)}
              selectedVariant={selectedVariant}
              isLoading={isLoadings}
              venuesCoverData={venuesCoverData}
            />
          ) : (
            <VenueSkipModalAction
              quantity={quantity}
              setQuantity={setQuantity}
              selectedSauces={selectedSauces}
              calculateTotalPrice={calculateTotalPrice}
              addItemToCart={() => handleCartAction(true, item)}
              onClose={onClose}
              isLoading={isLoadings}
            />
          )}
        </DialogActions>
      </ConfirmDialog>
    </>
  );
};
export default VenueItmeModal;
