import { Box, Stack, Typography, Grid } from "@mui/material";
import React from "react";
import AllVenuesFilter from "./AllVenuesFilter";
import AllVenuesCard from "./AllVenuesCard";
import { useSelector } from "react-redux";
import EmptyContent from "src/components/empty-content/EmptyContent";

const AllVenues = ({ searchQuery }) => {
  const { venues } = useSelector((state) => state.venues);
  const filteredVenues = venues?.filter((venue) => venue?.distance).filter((venue) =>
    venue.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <Box
      sx={{
        marginBottom: "70px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
          overflow: "hidden",
          "@media (max-width: 300px)": {
            fontSize: "12px",
          },
        }}
      >
        <Stack>
          <Typography variant="h3">All Restaurants</Typography>
        </Stack>
        {/* <AllVenuesFilter /> */}
      </Box>
      {filteredVenues.length > 0 ? (
        <Grid container spacing={2}>
          {filteredVenues.map((venue) => (
            <Grid item key={venue.id} xs={12} sm={6} md={4} lg={3}>
              <AllVenuesCard item={venue} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyContent
          title="No Data found"
          sx={{ "& span.MuiBox-root": { height: 160 } }}
        />
      )}
    </Box>
  );
};

export default AllVenues;
