import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const PromotionModalContent = ({ promotion }) => {

    const showOrderType = (type) => {
        switch (type) {
            case "pickUp":
                return "PickUp";
            case "QrDineIn":
                return "QR Dine In";
            case "QrPickUp":
                return "QR PickUp";
            case "carHop":
                return "Carhop QR";
            default:
                return "-";
        }
    };

    const formatDateTime = (time) => {
        const dateOptions = { day: "numeric", month: "short", year: "numeric" };
        const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
        const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(time);
        const formattedTime = new Intl.DateTimeFormat("en-US", timeOptions).format(time);

        return `${formattedDate} ${formattedTime}`;
    };

    return (
        <>
            {promotion?.map((elem, index) => {
                const { name, tag, endTime, amount, amountType, cappedAmount, minimumOrderAmount, orderType
                } = elem;
                return (
                    <Card key={index} sx={{ marginTop: 2, marginBottom: 2 }}>
                        <CardContent>
                            <Typography component="div"
                                sx={{
                                    fontWeight: 'bold'
                                }}>
                                {tag}
                            </Typography>
                            <Typography >
                                Valid Till: {formatDateTime(new Date(endTime))}
                            </Typography>
                            <Typography >
                                Name: {name}
                            </Typography>
                            {amount > 0 &&
                                <Typography >
                                    Amount: Rs. {amount}
                                </Typography>
                            }
                            <Typography >
                                Promotion Type: {amountType}
                            </Typography>
                            {cappedAmount > 0 &&
                                <Typography >
                                    Capped amount: Rs. {cappedAmount}
                                </Typography>
                            }
                            {minimumOrderAmount > 0 &&
                                <Typography >
                                    Minimum order amount: Rs. {minimumOrderAmount}
                                </Typography>
                            }
                            <Typography >
                                <Typography >
                                    Order type: {orderType.length > 0 ? orderType.map((type) => showOrderType(type)).join(", ") : "-"}
                                </Typography>
                            </Typography>
                            {/* <Typography color="text.secondary">
                                Description: {description ? description : '-'}
                            </Typography> */}

                        </CardContent>
                    </Card>
                );
            })}
        </>
    );
};

export default PromotionModalContent;
