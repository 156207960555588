import React from "react";
import { QrReader } from "react-qr-reader";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import Iconify from "src/components/iconify/Iconify";

const QrScanner = ({ onClose, onScan, onError }) => {
  return (
    <Dialog open={onScan} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogActions style={{ padding:"15px",display:"flex",justifyContent:"space-between"}}>
      <Typography sx={{fontWeight:"bold"}}>Scan QR code</Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <Iconify
            sx={{
              height: 25,
              width: 25,
            }}
            icon="mdi:close"
            color="#fca92e"
          />
        </IconButton>
      </DialogActions>
      <DialogContent style={{ padding: 0, position: "relative" }}> 
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "@media (max-width: 600px)": {
                  bottom: '33px'
                }
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "250px",
                  height: "250px",
                  border: "1px solid rgba(255, 255, 255, 0.5)",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: -5,
                    left: -5,
                    width: "30px",
                    height: "30px",
                    borderLeft: "4px solid white",
                    borderTop: "4px solid white",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: -5,
                    right: -5,
                    width: "30px",
                    height: "30px",
                    borderRight: "4px solid white",
                    borderTop: "4px solid white",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: -5,
                    left: -5,
                    width: "30px",
                    height: "30px",
                    borderLeft: "4px solid white",
                    borderBottom: "4px solid white",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: -5,
                    right: -5,
                    width: "30px",
                    height: "30px",
                    borderRight: "4px solid white",
                    borderBottom: "4px solid white",
                  }}
                />          
              </Box>
              {/* <Typography
                sx={{
                  position: "absolute",
                  bottom: "50px",
                  left: "50%",
                  transform: "translateX(-50%)", 
                  textAlign: "center",
                  color: "white",
                  fontWeight: "bold",
                  "@media (max-width: 600px)": {
                    top: "255px",
                  }
                }}
              >
                Place QR code within box
              </Typography> */}
            </Box>      
        <QrReader
          delay={300}
          onError={(err) => {
            onError(err);
          }}
          onResult={(data) => {
            onScan(data);
          }}
          style={{ width: "100%" }}
          constraints={{ facingMode: "environment" }}
          videoContainerStyle={{
            paddingTop: 0,
          }}
          videoStyle={{
            position: "relative", 
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default QrScanner;