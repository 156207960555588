export default function previousOrderType(venueId) {
    let orderType = localStorage.getItem("mode");
    let venueIdForQrScanned = localStorage.getItem("venueId");
    
    if (orderType === "QrDineIn" && venueIdForQrScanned !== venueId) {
        return "Set DineIn";
    } else if ( (orderType === "QrPickUp" ||  orderType === "carHop" ) && venueIdForQrScanned !== venueId) {
        return "Set PickUp";
    }
    return null; 
}
