import { Outlet } from 'react-router-dom';
// @mui
import { Stack, Container } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// config
import { HEADER } from '../../config-global';
//

import FooterLogin from './FooterLogin';
import HeaderLogin from '../login/HeaderLogin';
import VenueHeader from 'src/pages/Venue/VenueHeader';
// ----------------------------------------------------------------------

export default function CompactLayout({ children }) {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <>
      {/* <HeaderLogin isOffset={isOffset} /> */}
      {/* <VenueHeader/> */}

      <Container component="main">
        <Stack
          sx={{
            py: 5,
            m: 'auto',
            maxWidth: 400,
            minHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Outlet />
          {children}
        </Stack>
      </Container>
      <FooterLogin />
    </>
  );
}
