import {
    Stack,
    Typography,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    useMediaQuery, 
    useTheme,
    Button, 
    IconButton
} from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
export default function VenueFarAwayModal({ open, onClose, width }) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        flexGrow: 1,
                        textAlign: "center",
                        fontWeight: "700",
                        fontSize: "25px",
                    }}
                >
                   You are too far, try another venue
                </Typography>

                <IconButton aria-label="close" onClick={onClose}>
                <Iconify
                  sx={{
                    height: 25,
                    width: 25,
                  }}
                  icon="mdi:close"
                  color="#fca92e"
                />
              </IconButton>

            </DialogTitle>

            <Box>
                <Divider />
            </Box>

            <DialogContent sx={{ typography: "body2", padding: "24px" }}>
                <Stack spacing={2}>
                   <Typography variant="body2" color="text.secondary" sx={{textAlign: 'center'}}>
                     Sorry, you are too far from this venue. Please try again once you reach the venue or try another.
                    </Typography>
                </Stack>
            </DialogContent>
        </Dialog>

    );
}
