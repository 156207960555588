import {
    Box,
    Stack,
    Typography,
    Card,
    CardContent,
    Button
} from "@mui/material";
import { useSelector } from "react-redux";

const AvailablePromotion = ({ ApplyPromotion, CancelPromotion, appliedPromotion, venuePermissions, method, promotionError }) => {

    const { promotions } = useSelector((state) => state.promotions);
    const { configurations } = venuePermissions ?? {};
    const { isPromotionAvailable } = configurations ?? {};
    const { id } = appliedPromotion ?? {};

    const filteredPromotions = promotions?.filter((promo) =>
        promo.paymentMethodType === "both" || promo.paymentMethodType === method
    );

    return (
        <>
            {isPromotionAvailable && filteredPromotions.length > 0 && (
                <Box>
                    <Typography
                        variant="h3"
                        sx={{
                            fontWeight: "700",
                            marginLeft: "12px",
                            fontSize: "25px",
                            padding: "6px",
                        }}
                    >
                        Promotion
                    </Typography>
                    <Card
                        sx={{
                            border: "1px solid rgba(0, 0, 0, 0.10)",
                            borderBottom: "none",
                            borderRadius: "20px",
                        }}
                    >
                        {filteredPromotions.map((elem) => (
                            <Card
                                key={elem.id}
                                sx={{
                                    border: "1px solid rgba(0, 0, 0, 0.10)",
                                    borderBottom: "none",
                                    borderRadius: "20px",
                                    margin: '20px'
                                }}
                            >
                                <CardContent>
                                    <Stack spacing={1}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography sx={{ color: "text.secondary", fontWeight: "600" }}>
                                                {elem?.tag}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {id === elem.id ? (
                                                    <Button
                                                        onClick={() => CancelPromotion()}
                                                    >
                                                        Cancel
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        onClick={() => ApplyPromotion(elem)}
                                                        sx={{
                                                            variant: "contained",
                                                        }}
                                                    >
                                                        Apply
                                                    </Button>
                                                )}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    {promotionError[elem.id] && (
                                        <Typography
                                            variant="body2"
                                            style={{ color: "red", textAlign: "center" }}
                                        >
                                            {promotionError[elem.id]}
                                        </Typography>
                                    )}
                                </CardContent>
                            </Card>
                        ))}
                    </Card>
                </Box>
            )}
        </>
    );
};

export default AvailablePromotion;

