import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { Controller } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "../../../components/snackbar";
import { useSelector, useDispatch } from "react-redux";
import api from "src/utils/axios";
import Iconify from "src/components/iconify/Iconify";
import { useNavigate } from "react-router-dom";
import FormProvider, { RHFTextField } from "../../../components/hook-form";
import { updateUserProfileInfo } from "src/redux/slices/loginSlice";

export default function CarInformation({ open, onClose, width , handleCloseForCart,setIsUpsellingDialogOpen}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let orderType = localStorage.getItem("mode");
  const { cardItems } = useSelector((state) => state?.cardSlice);
  const { isLoggedIn, user } = useSelector((state) => state.login);
  const { enqueueSnackbar } = useSnackbar();

  const schema = Yup.object().shape({
    carNumber: Yup.string().required("Car number is required"),
    carColor: Yup.string().required("Car color is required"),
  });

  const defaultValues = { carNumber: cardItems?.data?.customer?.carNumber ? cardItems.data.customer.carNumber : user?.carNumber ? user?.carNumber : "",
    carColor: cardItems?.data?.customer?.carColor ? cardItems.data.customer.carColor : user?.carColor ? user?.carColor : "",
    notes: cardItems.data?.customer?.notes ?? "",
  };

  const carColors = [
    "White",
    "Silver",
    "Black",
    "Grey",
    "Blue",
    "Red",
    "Beige",
    "Maroon",
    "Green",
    "Brown",
    "Gold",
    "Yellow",
    "Orange",
    "Purple",
    "Teal",
    "Copper",
    "Burgundy",
    "Bronze",
    "Pink",
    "Turquoise",
    "Other",
  ];

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await api.patch(
        `${process.env.REACT_APP_BACKEND_URL}/cart/addCustomer/${cardItems.data.id}`,
        {
          customer: { carColor: data.carColor, carNumber: data.carNumber, notes: data.notes , ...(user ? { customerId: user?.id } : {}) },
          orderType: "carHop",
          saveInCustomer: data?.saveInCustomer
        }
      );
      if(data.saveInCustomer){
        dispatch(updateUserProfileInfo({ carColor: data.carColor, carNumber: data.carNumber}, user?.id));
      }
      if (isLoggedIn) {
        handleCloseForCart()
        setIsUpsellingDialogOpen(false)
        navigate(`/checkout`);
      } else {
        navigate(`/auth/login?type=${orderType}`);
      }
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      enqueueSnackbar("Error submitting form", { variant: "error" });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "700",
            fontSize: "25px",
          }}
        >
          Car Information
        </Typography>

        <IconButton aria-label="close" onClick={onClose}>
          <Iconify
            sx={{
              height: 25,
              width: 25,
            }}
            icon="mdi:close"
            color="#fca92e"
          />
        </IconButton>
      </DialogTitle>
      <Box>
        <Divider />
      </Box>
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        sx={{ pt: 2 }}
      >
        <DialogContent sx={{ typography: "body2", padding: "24px" }}>
          <Stack spacing={2}>
            <RHFTextField
              name="carNumber"
              control={control}
              label="Car Number"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                const valueWithoutSpaces = e.target.value.replace(/\s/g, ""); 
                methods.setValue("carNumber", valueWithoutSpaces); 
              }}
            />
            <FormControl fullWidth error={!!errors.carColor}>
              <InputLabel id="carColor">Car Color</InputLabel>
              <Controller
                name="carColor"
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="carColor"
                    value={field.value || ""}
                    label="Car Color"
                    onChange={field.onChange}
                  >
                    {carColors.map((color) => (
                      <MenuItem key={color} value={color}>
                        {color.charAt(0).toUpperCase() + color.slice(1)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.carColor && (
                <Typography
                  sx={{ fontSize: "12px", margin: "8px 14px 0px" }}
                  color="error"
                >
                  {errors.carColor.message}
                </Typography>
              )}
            </FormControl>

            <RHFTextField
              name="notes"
              label="Notes"
              control={control}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />
           {
            user && 
            <FormControlLabel
              control={
                <Controller
                  name="saveInCustomer"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value || false}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label="Save Information"
            />
           }

          </Stack>
        </DialogContent>
        <Box>
          <Divider />
        </Box>
        <DialogActions>
          <LoadingButton
            color="inherit"
            type="submit"
            variant="contained"
            sx={{
              bgcolor: "#FCA92E",
              color: (theme) =>
                theme.palette.mode === "light" ? "#000000" : "grey.800",
              "&:hover": {
                bgcolor: "#B66816",
                color: (theme) =>
                  theme.palette.mode === "light" ? "common.white" : "grey.800",
              },
            }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
