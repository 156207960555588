import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';
import Carousel, { CarouselArrows } from '../../../components/carousel';

CustomCarouselsPage.propTypes = {
  data: PropTypes.array,
  children: PropTypes.func,
};

export default function CustomCarouselsPage({ data, children }) {
  const carouselRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const widthPercentage = isMobile ? 100 : data.length === 1 ? 25 :
    data.length === 2 ? 50 :
      data.length === 3 ? 75 :
        100;
  const carouselSettings = {
    slidesToShow: Math.min(data?.length ?? 1, 4),
    infinite: false,
    rtl: Boolean(theme.direction === 'rtl'),
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 2.2 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1.2 },
      },
    ],
  };

  const handlePrev = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <Box
      sx={{
        overflow: 'hidden',
        position: 'relative',
        width: `${widthPercentage}%`,
      }}
    >
      {(isDesktop && data.length > 4) ? (
        <CarouselArrows filled onNext={handleNext} onPrevious={handlePrev}>
          <Carousel ref={carouselRef} {...carouselSettings}>
            {data.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  padding: index !== data.length - 1 ? '0 16px 0 0' : 0,
                }}
              >
                {children(item)}
              </Box>
            ))}
          </Carousel>
        </CarouselArrows>
      ) : (
        <Box>
          {isMobile && data.length == 1 ? (
            data.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  padding: index !== data.length - 1 ? '0 16px 0 0' : 0,
                }}
              >
                {children(item)}
              </Box>
            ))
          ) : (
            <Carousel ref={carouselRef} {...carouselSettings}>
              {data.map((item, index) => (
                <Box
                  key={item.id}
                  sx={{
                    padding: index !== data.length - 1 ? '0 16px 0 0' : 0,

                  }}
                >
                  {children(item)}
                </Box>
              ))}
            </Carousel>
          )}
        </Box>
      )}
    </Box>
  );
}

