import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isLoggedIn } = useSelector((state) => state.login);
  const { pathname } = useLocation();
  if (
    isLoggedIn &&
    (pathname === "/auth/login" ||
      pathname === "/auth/reset-password" ||
      pathname === "/auth/new-password" ||
      pathname === "/auth/verify" ||
      pathname === "/auth/verifysuccess" ||
      pathname === "/auth/email-check")
  ) {
    return <Navigate to="/venue" replace />;
  }

  // if (isLoggedIn && (pathname === '/auth/login' || pathname === '/auth/register' )) {
  //   return <Navigate to='/venue' replace />;
  // }
  // if (!isLoggedIn && (pathname !== '/auth/login' && pathname !== '/auth/register')) {
  //   return <Navigate to='/auth/login' replace />;
  // }
  // if (!isLoggedIn && pathname === 'order') {
  //   return <Navigate to='/auth/login' replace />;
  // }
  const address = localStorage.getItem("address");
  if (pathname === "/venue" && !address) {
    return <Navigate to="/" replace />;
  }
  if (address && pathname === "/") {
    return <Navigate to="/venue" replace />;
  }

  return <> {children} </>;
}
