import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const VenueSkipModalAction = ({
  calculateTotalPrice,
  addItemToCart,
  onClose,
  isLoading,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "@media (max-width: 600px)": {
            flexWrap: "wrap",
          },
        }}
      >
        <LoadingButton
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isLoading}
          onClick={addItemToCart}
          sx={{
            width: { xs: "100%", sm: "60%" },
            fontSize: "16px",
            padding: "8px 0px",
            marginTop: { xs: "20px", sm: "0" },
            marginRight:"10px",
            bgcolor: "#fda92d",
            color: (theme) =>
              theme.palette.mode === "black" ? "common." : "grey.800",
            "&:hover": {
              bgcolor: "#B66816",
              color: (theme) =>
                theme.palette.mode === "black" ? "common.white" : "grey.800",
            },
          }}
        >
          Yes, Add to cart
          <Typography
            sx={{
              fontWeight: "700",
              ml: 1,
              fontSize: "16px",
            }}
          >
            {calculateTotalPrice()}
          </Typography>
        </LoadingButton>

        <Button
          type="submit"
          variant="contained"
          color="primary"
           size="large"
          onClick={onClose}
          sx={{
            width: { xs: "100%", sm: "60%" },
            fontSize: "16px",
            padding: "8px 0px",
            "@media (max-width: 600px)": {
              width: "100%",
              marginTop: "20px",
            },
          }}
        >
          Close
        </Button>
      </Box>
    </>
  );
};

export default VenueSkipModalAction;
