import { Suspense, lazy } from 'react';

import LoadingScreen from '../components/loading-screen';
const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen  width="100px" height="100px" marginT="0" h="70vh" />}>
      <Component {...props} />
    </Suspense>
  );

export const SearchMainPage = Loadable(lazy(() => import('../pages/SearchMainPage')));
export const PaymentCustomPage = Loadable(lazy(() => import('../pages/PaymentCustomPage')));
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const CheckEmail = Loadable(lazy(() => import('../pages/auth/VerifyCheckEmail')));

